import axiosInstance from './api';

// Register a new user
export const register = async (userData) => {
  try {
    const response = await axiosInstance.post('/auth/register', userData);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return {
      success: true,
      token: response.data.token,
      user: response.data.user
    };
  } catch (error) {
    console.error('Registration error:', error.response?.data || error.message);
    return {
      success: false,
      message: error.response?.data?.message || 'Registration failed. Please try again.'
    };
  }
};

// Login a user
export const login = async (username, password) => {
  try {
    //console.log('Sending login request to:', '/auth/login');
    const response = await axiosInstance.post('/auth/login', { username, password });
    if (response.data.token && response.data.user) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    } else {
      throw new Error('Login failed: Invalid response from server');
    }
  } catch (error) {
    console.error('Detailed login error:', error.response || error);
    throw error;
  }
};


// Confirm password reset
export const resetPasswordConfirm = async (token, password) => {
  const response = await axiosInstance.put(`/auth/reset-password/${token}`, { password });
  return response.data;
};

// Check user roles
export const checkRoles = async () => {
  try {
    const response = await axiosInstance.get('/auth/check-roles');
    return response.data;
  } catch (error) {
    console.error('Error checking roles:', error);
    throw error;
  }
};

// Handle forgotten credentials
export const forgotCredentials = async (email, action) => {
  try {
    const payload = { email, action };
    //console.log('Payload size:', JSON.stringify(payload).length, 'bytes');
    const response = await axiosInstance.post('/auth/forgot', payload);
    return response;
  } catch (error) {
    console.error('Error in forgotCredentials:', error.response?.data || error.message);
    throw error;
  }
};

// Reset user password
export const resetUserPassword = async (userId) => {
  try {
    const response = await axiosInstance.post(`/users/${userId}/reset-password`);
    return response.data;
  } catch (error) {
    //console.error('Detailed error in resetUserPassword:', error);
    if (error.response) {
      //console.error('Error response:', error.response.data);
      //console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Check page permissions
export const checkPagePermissions = async (page) => {
  try {
    //console.log('Checking permission for page:', page);
    const response = await axiosInstance.get(`/auth/check-page-permissions/${page}`);
    //console.log('Permission response:', response.data);
    return response.data.hasPermission;
  } catch (error) {
    console.error('Error checking page permissions:', error);
    return false;
  }
};

// Check ability permissions
export const checkAbilityPermissions = (user, ability) => {
  if (!user) return false;
  
  // If user is admin, they have all permissions
  if (user.role === 'admin' || (user.abilities && user.abilities.includes('IsAdmin'))) {
    return true;
  }
  
  // Otherwise check specific ability
  if (!user.abilities) {
    return false;
  }
  return user.abilities.includes(ability);
};

export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axiosInstance.post('/auth/reset-password', {
      token,
      newPassword
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
