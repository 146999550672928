import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FiPlus, FiX } from 'react-icons/fi';
import * as extraService from '../../../services/dumpsterExtraService';
import { 
    calculateTotalServiceCharges, 
    calculateExtraValue, 
    calculateTotalExtraCharges, 
    calculateGrandTotal 
} from '../../../utils/calculationUtils';

const CustomerExtras = ({ customer, onUpdate }) => {
    const [availableExtras, setAvailableExtras] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedExtra, setSelectedExtra] = useState(null);
    const [customValue, setCustomValue] = useState('');
    const [isRecurring, setIsRecurring] = useState(false);
    const [notes, setNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [extras, setExtras] = useState([]);
    const [pendingChanges, setPendingChanges] = useState([]);

    useEffect(() => {
        console.log('CustomerExtras mounted');
        fetchAvailableExtras();
    }, []);

    useEffect(() => {
        if (customer?.extras) {
            setExtras(customer.extras);
        }
    }, [customer]);

    useEffect(() => {
        if (selectedExtra) {
            setCustomValue(selectedExtra.value?.toString() || '');
            setIsRecurring(selectedExtra.isRecurring || false);
        }
    }, [selectedExtra]);

    const fetchAvailableExtras = async () => {
        try {
            console.log('Fetching available extras...');
            const extras = await extraService.getExtras();
            console.log('Available extras:', extras);
            setAvailableExtras(extras);
        } catch (error) {
            console.error('Error fetching extras:', error);
            toast.error('Failed to fetch extras');
        }
    };

    const resetForm = () => {
        setSelectedExtra(null);
        setCustomValue('');
        setIsRecurring(false);
        setNotes('');
    };

    const handleAddExtra = async () => {
        if (isSubmitting) return;
        
        if (!selectedExtra) {
            toast.error('Please select an extra');
            return;
        }

        // Only check for duplicates if it's not a flat rate surcharge
        if (selectedExtra.calculationType !== 'flat' && selectedExtra.type === 'surcharge') {
            const existingExtra = extras?.find(e => e.extra._id === selectedExtra._id);
            if (existingExtra) {
                toast.error('This extra is already added to the customer');
                return;
            }
        }

        try {
            setIsSubmitting(true);
            
            // Create the data for the API
            const extraData = {
                extra: selectedExtra._id,
                customValue: customValue === '' ? null : parseFloat(customValue),
                isRecurring: isRecurring,
                notes: notes || ''
            };
            
            // Add to pending changes
            setPendingChanges(prev => [...prev, { type: 'add', data: extraData }]);
            
            // Update local state with full extra data for display
            const displayExtraData = {
                extra: {
                    ...selectedExtra,
                    value: customValue === '' ? selectedExtra.value : parseFloat(customValue),
                    isRecurring: isRecurring
                },
                customValue: customValue === '' ? null : parseFloat(customValue),
                notes: notes || ''
            };

            const updatedExtras = [...extras, displayExtraData];
            setExtras(updatedExtras);

            // Notify parent component of the change
            if (onUpdate) {
                onUpdate({
                    commitExtrasChanges: commitChanges,
                    revertExtrasChanges: revertChanges,
                    extras: updatedExtras
                });
            }
            
            toast.success(`Extra ${selectedExtra.name} added`);
            setShowAddModal(false);
            resetForm();
            
        } catch (error) {
            console.error('Error adding extra:', error);
            toast.error('Failed to add extra');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveExtra = async (extraToRemove) => {
        try {
            console.log('Removing extra:', extraToRemove);
            
            // Add to pending changes
            setPendingChanges(prev => [...prev, { type: 'remove', data: extraToRemove }]);
            
            // Update local state - filter based on both _id and extra._id to handle all cases
            const updatedExtras = extras.filter(e => {
                return e._id !== extraToRemove._id && e.extra._id !== extraToRemove.extra._id;
            });
            console.log('Updated extras after removal:', updatedExtras);
            setExtras(updatedExtras);

            // Notify parent component of the change
            if (onUpdate) {
                onUpdate({
                    commitExtrasChanges: commitChanges,
                    revertExtrasChanges: revertChanges,
                    extras: updatedExtras
                });
            }
            
            toast.success(`Extra ${extraToRemove.extra.name} removed`);
        } catch (error) {
            console.error('Error removing extra:', error);
            toast.error('Failed to remove extra');
        }
    };

    // Function to commit pending changes
    const commitChanges = async () => {
        try {
            console.log('Committing pending changes:', pendingChanges);
            
            if (pendingChanges.length === 0) {
                console.log('No pending changes to commit');
                return true;
            }

            for (const change of pendingChanges) {
                if (change.type === 'add') {
                    // Format the data for the API
                    const extraData = {
                        extraId: change.data.extra,
                        customValue: change.data.customValue,
                        isRecurring: change.data.isRecurring,
                        notes: change.data.notes
                    };
                    console.log('Adding extra with data:', extraData);
                    await extraService.addExtraToCustomer(customer._id, extraData);
                } else if (change.type === 'remove') {
                    const extraId = change.data.extra._id || change.data.extra;
                    console.log('Removing extra with ID:', extraId);
                    await extraService.removeExtraFromCustomer(customer._id, extraId);
                }
            }
            
            // Clear pending changes after successful commit
            setPendingChanges([]);
            
            // Update the extras in the parent component
            if (onUpdate) {
                onUpdate({
                    commitExtrasChanges: commitChanges,
                    revertExtrasChanges: revertChanges,
                    extras: extras
                });
            }
            
            // Show a success message for the extras changes
            if (pendingChanges.length > 0) {
                const addedCount = pendingChanges.filter(c => c.type === 'add').length;
                const removedCount = pendingChanges.filter(c => c.type === 'remove').length;
                
                let message = 'Successfully ';
                if (addedCount > 0) {
                    message += `added ${addedCount} extra${addedCount > 1 ? 's' : ''}`;
                    if (removedCount > 0) message += ' and ';
                }
                if (removedCount > 0) {
                    message += `removed ${removedCount} extra${removedCount > 1 ? 's' : ''}`;
                }
                toast.success(message);
            }
            
            return true;
        } catch (error) {
            console.error('Error committing changes:', error);
            toast.error('Failed to save changes');
            return false;
        }
    };

    // Function to revert pending changes
    const revertChanges = () => {
        setExtras(customer.extras || []);
        setPendingChanges([]);
    };

    // Expose commit and revert functions to parent
    useEffect(() => {
        if (onUpdate) {
            onUpdate({
                commitExtrasChanges: commitChanges,
                revertExtrasChanges: revertChanges,
                extras: extras
            });
        }
    }, [pendingChanges, extras]);

    // Filter out extras that are already added to the customer
    const availableExtraOptions = availableExtras.filter(extra => 
        !extras?.some(customerExtra => customerExtra.extra._id === extra._id)
    );

    // Calculate totals
    const baseTotal = calculateTotalServiceCharges(customer.services);
    const extraCharges = calculateTotalExtraCharges(baseTotal, extras || []);
    const grandTotal = calculateGrandTotal(customer.services, extras || []);

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium">Extras</h3>
                <button
                    type="button"
                    onClick={() => setShowAddModal(true)}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <FiPlus className="w-5 h-5 mr-1" />
                    Add Extra
                </button>
            </div>

            {/* List of current extras */}
            {extras?.map((extra, index) => (
                <div key={`${extra.extra._id}-${index}`} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div>
                        <div className="font-medium">{extra.extra.name}</div>
                        <div className="text-sm flex items-center">
                            <span className="text-gray-900">
                                {extra.customValue !== null ? (
                                    extra.extra.calculationType === 'flat' ? (
                                        `$${extra.customValue.toFixed(2)}`
                                    ) : (
                                        `${extra.customValue.toFixed(1)}%`
                                    )
                                ) : (
                                    extra.extra.calculationType === 'flat' ? (
                                        `$${extra.extra.value.toFixed(2)}`
                                    ) : (
                                        `${extra.extra.value.toFixed(1)}%`
                                    )
                                )}
                            </span>
                            <span className="ml-2 text-gray-500 text-xs uppercase tracking-wide">
                                • {extra.extra.isRecurring ? 'Recurring' : 'One Time'}
                            </span>
                        </div>
                        {extra.notes && (
                            <div className="text-sm text-gray-500 mt-1">
                                Note: {extra.notes}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={() => handleRemoveExtra(extra)}
                        className="p-2 text-gray-400 hover:text-gray-600"
                        type="button"
                    >
                        <FiX className="h-5 w-5" />
                    </button>
                </div>
            ))}

            {/* Add Extra Modal */}
            {showAddModal && (
                <div className="fixed inset-0 z-[10001] overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-full">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Add Extra</h3>
                                        <div className="space-y-4">
                                            {/* Extra Selection */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Select Extra</label>
                                                <select
                                                    value={selectedExtra?._id || ''}
                                                    onChange={(e) => {
                                                        const selected = availableExtras.find(extra => extra._id === e.target.value);
                                                        setSelectedExtra(selected);
                                                    }}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="">Select an extra</option>
                                                    {availableExtras.map(extra => (
                                                        <option key={extra._id} value={extra._id}>
                                                            {extra.name} - ${extra.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Custom Value */}
                                            {selectedExtra && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Value</label>
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm">$</span>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            value={customValue}
                                                            onChange={(e) => setCustomValue(e.target.value)}
                                                            className="pl-7 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            placeholder={selectedExtra.value?.toString()}
                                                            step="0.01"
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {/* Notes */}
                                            {selectedExtra && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Notes</label>
                                                    <textarea
                                                        value={notes}
                                                        onChange={(e) => setNotes(e.target.value)}
                                                        rows="2"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Add any notes..."
                                                    />
                                                </div>
                                            )}

                                            {/* Is Recurring */}
                                            {selectedExtra && (
                                                <div className="flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={isRecurring}
                                                        onChange={(e) => setIsRecurring(e.target.checked)}
                                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                    />
                                                    <label className="ml-2 block text-sm text-gray-900">
                                                        Recurring Extra
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={handleAddExtra}
                                    disabled={isSubmitting}
                                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                >
                                    Add
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowAddModal(false);
                                        resetForm();
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Display totals */}
            <div className="mt-4 space-y-1 text-right">
                <div className="text-sm text-gray-600">
                    Base Total: ${baseTotal.toFixed(2)}
                </div>
                <div className="text-sm text-gray-600">
                    Extra Charges: ${extraCharges.toFixed(2)}
                </div>
                <div className="font-bold">
                    Grand Total: ${grandTotal.toFixed(2)}
                </div>
            </div>
        </div>
    );
};

export default CustomerExtras;
