import React, { useState } from 'react';
import { forgotCredentials } from '../services/authService';
import { FiX } from 'react-icons/fi';
import '../App.css';

function ForgotCredentialsModal({ isOpen, onClose }) {
  const [email, setEmail] = useState('');
  const [action, setAction] = useState('retrieve_username');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    try {
      const response = await forgotCredentials(email, action);
      setMessage(response.data.message);
      setEmail('');
    } catch (err) {
      console.error('Forgot Credentials error:', err);
      setError(err.response?.data?.message || err.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FiX size={24} />
        </button>
        
        <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">
          {action === 'retrieve_username' ? 'Forgot Username' : 'Reset Password'}
        </h2>

        {message && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
            {message}
          </div>
        )}
        
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter your registered email"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              I want to:
            </label>
            <div className="space-y-2">
              <label className="flex items-center space-x-3 cursor-pointer">
                <input
                  type="radio"
                  value="retrieve_username"
                  checked={action === 'retrieve_username'}
                  onChange={(e) => setAction(e.target.value)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                />
                <span className="text-gray-700">Retrieve my username</span>
              </label>
              <label className="flex items-center space-x-3 cursor-pointer">
                <input
                  type="radio"
                  value="reset_password"
                  checked={action === 'reset_password'}
                  onChange={(e) => setAction(e.target.value)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                />
                <span className="text-gray-700">Reset my password</span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
          >
            {action === 'retrieve_username' ? 'Retrieve Username' : 'Reset Password'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotCredentialsModal;