import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { checkPagePermissions } from '../services/authService';
import NavigationBar from './NavigationBar';

function ProtectedRoute({ children, requiredAbility, requiredPage }) {
  const { user, loading } = useAuth();
  const [hasPageAccess, setHasPageAccess] = useState(true);
  const [activeTab, setActiveTab] = React.useState('welcome');
  const [canViewAllWorkOrders, setCanViewAllWorkOrders] = React.useState(false);
  const [canUpdateMeters, setCanUpdateMeters] = React.useState(false);
  const [canBeAssigned, setCanBeAssigned] = React.useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      if (user && requiredPage) {
        const hasAccess = await checkPagePermissions(requiredPage);
        setHasPageAccess(hasAccess);
      }
    };
    checkAccess();
  }, [user, requiredPage]);

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Redirect to login if not authenticated
  if (!user) {
    return <Navigate to="/login" />;
  }

  // For page-level protection, check if access is granted
  if (requiredPage && !hasPageAccess) {
    return <Navigate to="/unauthorized" />;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Clone children and pass additional props
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { activeTab, handleTabChange });
    }
    return child;
  });

  return (
    <>
      <NavigationBar 
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        canViewAllWorkOrders={canViewAllWorkOrders}
        canUpdateMeters={canUpdateMeters}
        canBeAssigned={canBeAssigned}
      />
      {childrenWithProps}
    </>
  );
}

export default ProtectedRoute;
