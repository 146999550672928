import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import axiosInstance from '../../../services/api';

const ExtraManagement = () => {
    const [extras, setExtras] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editingExtra, setEditingExtra] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        type: 'surcharge',
        calculationType: 'flat',
        value: '',
        description: '',
        isRecurring: true
    });

    useEffect(() => {
        console.log('ExtraManagement component mounted');
        fetchExtras();
    }, []);

    const fetchExtras = async () => {
        try {
            console.log('Fetching extras...');
            const response = await axiosInstance.get('/dumpsterextras');
            console.log('Extras response:', response.data);
            setExtras(response.data);
        } catch (error) {
            console.error('Error fetching extras:', error);
            toast.error('Failed to fetch extras');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingExtra) {
                console.log('Updating extra:', editingExtra._id, formData);
                await axiosInstance.put(`/dumpsterextras/${editingExtra._id}`, formData);
                toast.success('Extra updated successfully');
            } else {
                console.log('Creating new extra:', formData);
                await axiosInstance.post('/dumpsterextras', formData);
                toast.success('Extra created successfully');
            }
            setShowAddModal(false);
            setEditingExtra(null);
            resetForm();
            fetchExtras();
        } catch (error) {
            console.error('Error saving extra:', error);
            toast.error('Failed to save extra');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this extra?')) return;
        
        try {
            console.log('Deleting extra:', id);
            await axiosInstance.delete(`/dumpsterextras/${id}`);
            toast.success('Extra deleted successfully');
            fetchExtras();
        } catch (error) {
            console.error('Error deleting extra:', error);
            toast.error('Failed to delete extra');
        }
    };

    const resetForm = () => {
        setFormData({
            name: '',
            type: 'surcharge',
            calculationType: 'flat',
            value: '',
            description: '',
            isRecurring: true
        });
    };

    const handleInputChange = (e) => {
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value }));
    };

    console.log('Current extras:', extras);

    const renderForm = () => (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Type</label>
                <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                    <option value="surcharge">Surcharge</option>
                    <option value="discount">Discount</option>
                </select>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Calculation Type</label>
                <select
                    name="calculationType"
                    value={formData.calculationType}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                    <option value="flat">Flat Rate</option>
                    <option value="percentage">Percentage</option>
                </select>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Value</label>
                <input
                    type="number"
                    name="value"
                    value={formData.value}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                    step="0.01"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    rows="3"
                />
            </div>
            <div className="flex items-center space-x-3">
                <label className="relative inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        name="isRecurring"
                        checked={formData.isRecurring}
                        onChange={handleInputChange}
                        className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-700">Recurring Charge</span>
                </label>
            </div>
            <div className="mt-4 flex justify-end space-x-2">
                <button
                    type="button"
                    onClick={() => {
                        setShowAddModal(false);
                        setEditingExtra(null);
                        resetForm();
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {editingExtra ? 'Update' : 'Create'}
                </button>
            </div>
        </form>
    );

    const renderExtrasList = () => (
        <div className="mt-8">
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Calculation</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Value</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recurring</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {extras.map((extra) => (
                            <tr key={extra._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{extra.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{extra.type}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{extra.calculationType}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {extra.calculationType === 'percentage' ? `${extra.value}%` : `$${extra.value}`}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {extra.isRecurring ? 'Yes' : 'No'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <button
                                        onClick={() => {
                                            setEditingExtra(extra);
                                            setFormData(extra);
                                            setShowAddModal(true);
                                        }}
                                        className="text-blue-600 hover:text-blue-900 mr-4"
                                    >
                                        <FiEdit2 className="h-5 w-5" />
                                    </button>
                                    <button
                                        onClick={() => handleDelete(extra._id)}
                                        className="text-red-600 hover:text-red-900"
                                    >
                                        <FiTrash2 className="h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="mt-20 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h2 className="text-2xl font-semibold">Extras</h2>
                </div>
                <button
                    onClick={() => setShowAddModal(true)}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Add Extra
                </button>
            </div>

            {renderExtrasList()}

            {showAddModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">
                            {editingExtra ? 'Edit Extra' : 'Add Extra'}
                        </h3>
                        {renderForm()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExtraManagement;
