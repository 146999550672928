import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FiEdit, FiCheckSquare, FiXSquare, FiSend, FiUserPlus, FiUserMinus, FiCalendar, FiMessageSquare, FiX, FiMail, FiPhone, FiTrash2, FiMapPin, FiEye } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import { updateWorkOrder, getWorkOrderComments, addWorkOrderComment, assignUsersToWorkOrder, removeAssignedUsersFromWorkOrder, getWorkOrderById, deleteWorkOrder, deleteWorkOrderComment } from '../services/workOrderService';
import { getUsers } from '../services/userService';
import { getEquipmentCategory } from '../services/equipmentService';
import { useAuth } from '../hooks/useAuth';
import AssignUserModal from './AssignUserModal';
import { GrUserWorker } from 'react-icons/gr';
import { AiOutlineUserDelete } from 'react-icons/ai';
import WorkOrderCompletionModal from './WorkOrderCompletionModal';
import { TbEyeCheck } from 'react-icons/tb';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { addDays, isWithinInterval } from 'date-fns';
import ModalPortal from './ModalPortal';

// Comment component extracted outside of WorkOrderModal
const Comment = ({ 
  comment, 
  isReply = false, 
  isReplying, 
  newComment, 
  onReply, 
  onAddComment, 
  onCancelReply, 
  onCommentChange,
  onDelete,
  currentUser
}) => {
  const replyTextareaRef = useRef(null);
  
  // Check if the current user can delete this specific comment
  const canDeleteThisComment = useMemo(() => {
    // Debug log for checking permissions
    console.log('Checking delete permissions:', {
      currentUser: {
        id: currentUser?.id,
        roles: currentUser?.roles
      },
      comment: {
        id: comment._id,
        authorId: comment.author?._id
      }
    });

    if (!currentUser) return false;

    // Check if user is admin or has delete permission
    const hasAdminPermission = currentUser.roles?.some(role => 
      role.name === 'Admin' || role.abilities?.includes('CanDeleteComments')
    );

    // Check if user is the comment author
    const isCommentAuthor = currentUser.id === comment.author?._id;

    console.log('Permission check results:', {
      hasAdminPermission,
      isCommentAuthor,
      canDelete: hasAdminPermission || isCommentAuthor
    });

    return hasAdminPermission || isCommentAuthor;
  }, [currentUser, comment]);

  useEffect(() => {
    if (isReplying && replyTextareaRef.current) {
      replyTextareaRef.current.focus();
    }
  }, [isReplying]);

  return (
    <div className={`bg-gray-50 p-3 rounded-lg ${isReply ? 'ml-6 border-l-2 border-gray-200' : ''}`}>
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-2">
          <span className="font-medium text-gray-700">
            {comment.author?.displayName || comment.author?.username || 'Unknown User'}
          </span>
          <span className="text-sm text-gray-500">
            {new Date(comment.createdAt).toLocaleDateString()}
          </span>
          {!isReply && (
            <button
              onClick={() => onReply(comment)}
              className="text-blue-500 hover:text-blue-600 flex items-center gap-1 text-sm"
            >
              <FiMessageSquare className="w-3 h-3" />
              Reply
            </button>
          )}
        </div>
        {canDeleteThisComment && (
          <button
            onClick={() => onDelete(comment._id)}
            className="text-gray-400 hover:text-red-500 transition-colors"
            title="Delete comment"
          >
            <FiTrash2 className="w-4 h-4" />
          </button>
        )}
      </div>

      <p className="mt-1 text-gray-600 whitespace-pre-wrap">{comment.text}</p>

      {comment.replies && comment.replies.length > 0 && (
        <div className="mt-3 space-y-3">
          {comment.replies.map((reply, index) => (
            <Comment
              key={reply._id || index}
              comment={reply}
              isReply={true}
              isReplying={false}
              onReply={onReply}
              onAddComment={onAddComment}
              onCancelReply={onCancelReply}
              onCommentChange={onCommentChange}
              onDelete={onDelete}
              currentUser={currentUser}
            />
          ))}
        </div>
      )}

      {isReplying && (
        <div className="mt-3 space-y-2">
          <div className="flex gap-2">
            <textarea
              ref={replyTextareaRef}
              value={newComment}
              onChange={onCommentChange}
              placeholder="Type your reply..."
              className="flex-1 p-2 border rounded-md min-h-[60px] focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex flex-col gap-1">
              <button
                onClick={onAddComment}
                disabled={!newComment.trim()}
                className={`px-3 py-1 rounded-md ${
                  newComment.trim()
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                }`}
              >
                Reply
              </button>
              <button
                onClick={onCancelReply}
                className="px-3 py-1 rounded-md border border-gray-300 hover:bg-gray-100"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function WorkOrderModal({ workOrder: initialWorkOrder, onClose, onUpdate, isInlineDisplay, currentUser }) {
  const [showContactModal, setShowContactModal] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [description, setDescription] = useState(initialWorkOrder.description || '');
  const [comments, setComments] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const textareaRef = useRef(null);
  const [isEditingScheduledDate, setIsEditingScheduledDate] = useState(false);
  const [newScheduledDate, setNewScheduledDate] = useState(null);
  const [originalScheduledDate, setOriginalScheduledDate] = useState(null);
  const [commentPlaceholder, setCommentPlaceholder] = useState('Add a comment...');
  const [showViewersModal, setShowViewersModal] = useState(false);
  const [viewerDetails, setViewerDetails] = useState({});
  const [isLoadingViewers, setIsLoadingViewers] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  
  // Simplified author check
  const isAuthor = currentUser?.id === initialWorkOrder.createdBy?._id;
  
  // Debug current user and permissions
  console.log('User and Permissions:', {
    currentUser,
    authorId: initialWorkOrder.createdBy?._id,
    isAuthor,
    viewersCount: initialWorkOrder.viewedBy?.length || 0
  });

  // Update description when work order changes
  useEffect(() => {
    setDescription(initialWorkOrder.description || '');
  }, [initialWorkOrder.description]);

  // Reset showAllComments when work order changes
  useEffect(() => {
    setShowAllComments(false);
  }, [initialWorkOrder._id]);

  // Fetch comments when work order changes
  useEffect(() => {
    const fetchComments = async () => {
      setIsLoadingComments(true);
      try {
        const fetchedComments = await getWorkOrderComments(initialWorkOrder._id);
        console.log('Raw fetched comments:', fetchedComments);

        // Group comments by parentId
        const commentsByParent = fetchedComments.reduce((acc, comment) => {
          const parentId = comment.parentId || 'root';
          acc[parentId] = acc[parentId] || [];
          acc[parentId].push(comment);
          return acc;
        }, {});

        // Function to recursively build comment tree
        const buildCommentTree = (parentId = 'root') => {
          const children = commentsByParent[parentId] || [];
          return children
            .filter(comment => !comment.isReply) // Avoid processing replies twice
            .map(comment => ({
              ...comment,
              replies: buildCommentTree(comment._id)
                .map(reply => ({ ...reply, isReply: true }))
            }));
        };

        // Build the comment tree starting from root level
        let processedComments = buildCommentTree();

        // Sort top-level comments by date (newest first)
        processedComments = processedComments.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Sort replies within each comment by date (oldest first)
        const sortReplies = (comments) => {
          comments.forEach(comment => {
            if (comment.replies && comment.replies.length > 0) {
              comment.replies.sort((a, b) => 
                new Date(a.createdAt) - new Date(b.createdAt)
              );
              sortReplies(comment.replies);
            }
          });
        };

        sortReplies(processedComments);
        console.log('Processed comment tree:', processedComments);

        setComments(processedComments);
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      } finally {
        setIsLoadingComments(false);
      }
    };

    fetchComments();
  }, [initialWorkOrder._id]);

  // Get visible comments based on showAllComments state
  const visibleComments = showAllComments 
    ? [...comments].reverse() 
    : [...comments].reverse().slice(-3);
  const hiddenCommentsCount = Math.max(0, comments.length - 3);

  // Check if user can edit the work order
  const canEditWorkOrder = currentUser && (
    currentUser.roles?.some(role => role.abilities?.includes('CanEditAllWorkOrders')) ||
    currentUser._id === initialWorkOrder.createdBy?._id
  );

  // Function to format date consistently
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString();
  };

  // Handle schedule date click
  const handleScheduleDateClick = () => {
    if (canEditWorkOrder) {
      setIsEditingScheduledDate(true);
      setNewScheduledDate(initialWorkOrder.dateScheduled ? new Date(initialWorkOrder.dateScheduled) : new Date());
      setOriginalScheduledDate(initialWorkOrder.dateScheduled ? new Date(initialWorkOrder.dateScheduled) : null);
      setCommentPlaceholder('Add reason for schedule change...');
    }
  };

  // Handle date selection
  const handleDateSelect = (date) => {
    setNewScheduledDate(date);
  };

  // Handle comment submission with date change
  const handleAddComment = useCallback(async () => {
    if (!newComment.trim() && !newScheduledDate) return;

    try {
      let commentText = newComment;
      
      // If there's a date change, handle it
      if (newScheduledDate && originalScheduledDate?.getTime() !== newScheduledDate.getTime()) {
        const oldDate = formatDate(originalScheduledDate);
        const newDate = formatDate(newScheduledDate);
        const dateChangeText = `Changed scheduled date from ${oldDate} to ${newDate}.`;
        
        // Update the work order with new date
        await updateWorkOrder(initialWorkOrder._id, {
          ...initialWorkOrder,
          dateScheduled: newScheduledDate
        });

        // If there's no comment, just use the date change text
        commentText = commentText.trim() ? `${dateChangeText}\n\n${commentText}` : dateChangeText;
        
        if (onUpdate) {
          onUpdate();
        }
      }

      if (commentText) {
        const commentData = {
          text: commentText,
          parentId: replyingTo ? replyingTo._id : null
        };
        
        const addedComment = await addWorkOrderComment(initialWorkOrder._id, commentData);
        
        setComments(prevComments => {
          if (replyingTo) {
            return prevComments.map(comment => {
              if (comment._id === replyingTo._id) {
                return {
                  ...comment,
                  replies: [...(comment.replies || []), { ...addedComment, isReply: true }]
                };
              }
              return comment;
            });
          }
          return [...prevComments, { ...addedComment, replies: [] }];
        });
      }
      
      setNewComment('');
      setReplyingTo(null);
      setIsEditingScheduledDate(false);
      setNewScheduledDate(null);
      setOriginalScheduledDate(null);
      setCommentPlaceholder('Add a comment...');
    } catch (error) {
      console.error('Failed to update work order or add comment:', error);
    }
  }, [newComment, replyingTo, initialWorkOrder._id, newScheduledDate, originalScheduledDate, onUpdate, formatDate]);

  const handleReply = useCallback((comment) => {
    setReplyingTo(comment);
  }, []);

  const cancelReply = useCallback(() => {
    setReplyingTo(null);
    setNewComment('');
  }, []);

  const handleCommentChange = useCallback((e) => {
    const textarea = e.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    setNewComment(e.target.value);
    
    // Use setTimeout instead of requestAnimationFrame for more reliable cursor positioning
    setTimeout(() => {
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(start, end);
      }
    }, 0);
  }, []);

  // Debug logs
  console.log('Current User:', currentUser);
  console.log('Work Order Creator:', initialWorkOrder.createdBy);

  const canEditDescription = currentUser && (
    currentUser.roles?.some(role => role.abilities?.includes('CanEditAllWorkOrders')) ||
    currentUser._id === initialWorkOrder.createdBy?._id
  );

  // Debug log
  console.log('Can Edit Description:', canEditDescription);

  const handleDescriptionEdit = async () => {
    if (!isEditingDescription) {
      setIsEditingDescription(true);
      return;
    }

    try {
      const updatedWorkOrder = await updateWorkOrder(initialWorkOrder._id, {
        ...initialWorkOrder,
        description
      });
      setIsEditingDescription(false);
      if (onUpdate) {
        onUpdate(updatedWorkOrder);
      }
    } catch (error) {
      console.error('Failed to update description:', error);
    }
  };

  const handleCancelEdit = () => {
    setDescription(initialWorkOrder.description || '');
    setIsEditingDescription(false);
  };

  console.log('Work Order Data:', {
    orderNumber: initialWorkOrder.orderNumber,
    dateCreated: initialWorkOrder.dateCreated,
    createdAt: initialWorkOrder.createdAt
  });

  const handleAuthorClick = () => {
    setShowContactModal(true);
  };

  const ContactModal = () => {
    const author = initialWorkOrder.createdBy;
    if (!author) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[13000]">
        <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
          <button
            onClick={() => setShowContactModal(false)}
            className="absolute top-0 right-1 text-gray-500 hover:text-gray-700 bg-white rounded-full p-0.5 shadow-lg"
          >
            <FiX className="w-4 h-4" />
          </button>

          <h2 className="text-xl font-bold mb-4">Contact Information</h2>
          
          <div className="space-y-4">
            <div>
              <h3 className="text-sm text-gray-500">Name</h3>
              <p className="text-lg">{author.firstname} {author.lastname}</p>
            </div>

            <div>
              <h3 className="text-sm text-gray-500">Email</h3>
              <a 
                href={`mailto:${author.email}`}
                className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
              >
                <FiMail className="w-4 h-4" />
                {author.email}
              </a>
            </div>

            {author.cellPhone && (
              <div>
                <h3 className="text-sm text-gray-500">Cell Phone</h3>
                <div className="flex gap-4">
                  <a 
                    href={`tel:${author.cellPhone}`}
                    className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
                  >
                    <FiPhone className="w-4 h-4" />
                    {author.cellPhone}
                  </a>
                  <a 
                    href={`sms:${author.cellPhone}`}
                    className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
                  >
                    <FiMessageSquare className="w-4 h-4" />
                    Message
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  // Check if user can delete comments (admin, has specific permission, or is comment author)
  const canDeleteComment = useCallback((comment) => {
    return currentUser && (
      currentUser.roles?.some(role => 
        role.name === 'Admin' || role.abilities?.includes('CanDeleteComments')
      ) ||
      currentUser._id === comment.author?._id
    );
  }, [currentUser]);
  
  console.log('Current user:', currentUser); // Debug log
  console.log('Current user roles:', currentUser?.roles); // Debug log
  console.log('Can delete comments:', canDeleteComment); // Debug log

  const handleDeleteComment = useCallback(async (commentId) => {
    try {
      await deleteWorkOrderComment(initialWorkOrder._id, commentId);
      
      // Remove the comment and its replies from the state
      setComments(prevComments => {
        const removeComment = (comments) => {
          return comments.filter(comment => {
            if (comment._id === commentId) {
              return false;
            }
            if (comment.replies?.length > 0) {
              comment.replies = removeComment(comment.replies);
            }
            return true;
          });
        };
        
        return removeComment(prevComments);
      });
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  }, [initialWorkOrder._id]);

  const handleLocationClick = useCallback(() => {
    const location = initialWorkOrder.location;
    let searchQuery;
    
    if (location) {
      // If we have lat/long, use those for more accurate mapping
      if (location.latitude && location.longitude) {
        searchQuery = `${location.latitude},${location.longitude}`;
      } else {
        // Otherwise construct address string
        const addressParts = [
          location.address,
          location.city,
          location.state
        ].filter(Boolean);
        searchQuery = encodeURIComponent(addressParts.join(', '));
      }
    } else if (initialWorkOrder.offSiteLocation) {
      searchQuery = encodeURIComponent(initialWorkOrder.offSiteLocation);
    }
    
    // Check if it's a mobile device
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      window.location.href = `geo:0,0?q=${searchQuery}`;
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${searchQuery}`, '_blank');
    }
  }, [initialWorkOrder.location, initialWorkOrder.offSiteLocation]);

  // Handle modal close with unsaved date changes
  const handleModalClose = async () => {
    if (newScheduledDate && originalScheduledDate?.getTime() !== newScheduledDate.getTime()) {
      const oldDate = formatDate(originalScheduledDate);
      const newDate = formatDate(newScheduledDate);
      const dateChangeText = `Changed scheduled date from ${oldDate} to ${newDate}.`;
      
      try {
        // Update the work order with new date
        await updateWorkOrder(initialWorkOrder._id, {
          ...initialWorkOrder,
          dateScheduled: newScheduledDate
        });

        // Add automatic comment
        await addWorkOrderComment(initialWorkOrder._id, {
          text: dateChangeText
        });

        if (onUpdate) {
          onUpdate();
        }
      } catch (error) {
        console.error('Failed to save date change:', error);
      }
    }
    onClose();
  };

  // Debug viewer data when modal opens
  useEffect(() => {
    if (showViewersModal) {
      console.log('Viewer Data Debug:', {
        viewedBy: initialWorkOrder.viewedBy,
        firstViewer: initialWorkOrder.viewedBy?.[0],
        viewerKeys: initialWorkOrder.viewedBy?.[0] ? Object.keys(initialWorkOrder.viewedBy[0]) : [],
        rawWorkOrder: initialWorkOrder
      });
    }
  }, [showViewersModal, initialWorkOrder]);

  // Fetch viewer details when modal opens
  useEffect(() => {
    async function fetchViewerDetails() {
      if (showViewersModal && initialWorkOrder.viewedBy?.length > 0) {
        setIsLoadingViewers(true);
        try {
          const users = await getUsers();
          const viewersMap = {};
          users.forEach(user => {
            viewersMap[user._id] = user;
          });
          setViewerDetails(viewersMap);
        } catch (error) {
          console.error('Failed to fetch viewer details:', error);
        } finally {
          setIsLoadingViewers(false);
        }
      }
    }
    fetchViewerDetails();
  }, [showViewersModal, initialWorkOrder.viewedBy]);

  // Function to check if date is within a week
  const isWithinWeek = useCallback((date) => {
    if (!date) return false;
    const today = new Date();
    const oneWeekFromNow = addDays(today, 7);
    return isWithinInterval(new Date(date), { start: today, end: oneWeekFromNow });
  }, []);

  // Handle postpone/activate click
  const handlePostponeClick = useCallback(() => {
    // If work order is already postponed, activate it
    if (initialWorkOrder.status === 'postponed') {
      updateWorkOrder(initialWorkOrder._id, {
        ...initialWorkOrder,
        status: 'open'
      }).then(() => {
        toast.success('Work order activated successfully', {
          position: "top-right",
          autoClose: 3000
        });
        if (onUpdate) onUpdate();
        onClose();
      }).catch(error => {
        console.error('Failed to activate work order:', error);
        toast.error('Failed to activate work order', {
          position: "top-right",
          autoClose: 5000
        });
      });
      return;
    }

    // Otherwise, check date and postpone
    if (isWithinWeek(initialWorkOrder.dateScheduled)) {
      toast.error('Change Scheduled Date to more than 1 week out to postpone the work order', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    // Update work order status to postponed
    updateWorkOrder(initialWorkOrder._id, {
      ...initialWorkOrder,
      status: 'postponed'
    }).then(async () => {
      // Add the postponement comment if there is one
      if (newComment.trim()) {
        try {
          await addWorkOrderComment(initialWorkOrder._id, {
            text: `Work Order Postponed: ${newComment.trim()}`
          });
        } catch (error) {
          console.error('Failed to add postponement comment:', error);
        }
      }
      
      toast.success('Work order postponed successfully', {
        position: "top-right",
        autoClose: 3000
      });
      if (onUpdate) onUpdate();
      onClose();
    }).catch(error => {
      console.error('Failed to postpone work order:', error);
      toast.error('Failed to postpone work order', {
        position: "top-right",
        autoClose: 5000
      });
    });
  }, [initialWorkOrder, onUpdate, onClose, isWithinWeek, newComment]);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteWorkOrder(initialWorkOrder._id);
      toast.success('Work order deleted successfully', {
        position: "top-right",
        autoClose: 3000
      });
      if (onUpdate) onUpdate();
      onClose();
    } catch (error) {
      console.error('Failed to delete work order:', error);
      toast.error('Failed to delete work order', {
        position: "top-right",
        autoClose: 5000
      });
    }
  }, [initialWorkOrder._id, onUpdate, onClose]);

  return (
    <div className={`
      ${!isInlineDisplay ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center -mt-[35px]' : ''}
      ${isInlineDisplay ? 'relative w-full h-full -mt-[35px]' : ''}
      ${!isInlineDisplay ? 'z-[9999]' : 'z-[8000]'}
    `}>
      <div className={`
        bg-white rounded-lg p-6 
        ${isInlineDisplay ? 'w-full h-full' : 'w-full max-w-2xl max-h-[90vh]'}
        overflow-y-auto relative
      `}>
        {!isInlineDisplay && (
          <button
            onClick={handleModalClose}
            className="absolute top-0 right-1 text-gray-500 hover:text-gray-700 bg-white rounded-full p-0.5 shadow-lg"
          >
            <FiX className="w-4 h-4" />
          </button>
        )}

        {/* Status Stamp - Moved outside of dates section */}
        {initialWorkOrder.status !== 'open' && (
          <div className={`
            absolute top-8 left-1/2 transform -translate-x-1/2
            -rotate-12 border-2 rounded-lg px-6 py-2 font-bold text-lg z-10
            ${initialWorkOrder.status === 'postponed' ? 'border-blue-500 text-blue-500 bg-blue-50/70' : ''}
            ${initialWorkOrder.status === 'closed' ? 'border-red-500 text-red-500 bg-red-50/70' : ''}
          `}>
            {initialWorkOrder.status.toUpperCase()}
          </div>
        )}

        <div className="space-y-4">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-baseline gap-2">
                <h2 className="text-xl font-bold">
                  {initialWorkOrder.equipment?.ID}
                </h2>
                <span className="text-gray-500">
                  {initialWorkOrder.category?.name}
                </span>
              </div>
              <p className="mt-1">
                {initialWorkOrder.title}
              </p>
              <button
                onClick={handleLocationClick}
                className="flex items-center text-blue-500 hover:text-blue-600 transition-colors mt-1 group"
              >
                <FiMapPin className="w-4 h-4 mr-1" />
                <span className="text-sm group-hover:underline">
                  {initialWorkOrder.location?.name}
                  {initialWorkOrder.offSiteLocation && (
                    <span className="ml-1 text-gray-500">
                      ({initialWorkOrder.offSiteLocation})
                    </span>
                  )}
                </span>
              </button>
            </div>
            <div className="text-right">
              <div className="space-y-1 mb-2">
                <span className="text-gray-600 block">
                  Work Order #{initialWorkOrder.orderNumber}
                </span>
                <span className="text-gray-600 block text-sm">
                  Creation Date: {initialWorkOrder.dateCreated ? new Date(initialWorkOrder.dateCreated).toLocaleDateString() : 'N/A'}
                </span>
                {initialWorkOrder.dateRequested && (
                  <span className="text-gray-600 block text-sm">
                    Requested Date: {new Date(initialWorkOrder.dateRequested).toLocaleDateString()}
                  </span>
                )}
                <span className="text-gray-600 block text-sm flex items-center justify-end gap-1">
                  {canEditWorkOrder && (
                    <button
                      onClick={handleScheduleDateClick}
                      className="text-gray-400 hover:text-blue-500 transition-colors"
                    >
                      <FiCalendar className="w-4 h-4" />
                    </button>
                  )}
                  Scheduled Date: {formatDate(initialWorkOrder.dateScheduled)}
                </span>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg relative">
            {isEditingDescription ? (
              <div className="space-y-2">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-2 border rounded-md min-h-[100px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter description..."
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={handleCancelEdit}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FiXSquare className="w-5 h-5" />
                  </button>
                  <button
                    onClick={handleDescriptionEdit}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FiCheckSquare className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <p className="whitespace-pre-wrap text-base min-h-[60px] max-h-[300px] overflow-y-auto">
                  {description || 'No description provided'}
                </p>
                {canEditDescription && (
                  <button
                    onClick={handleDescriptionEdit}
                    className="absolute bottom-2 right-2 text-gray-400 hover:text-gray-600"
                  >
                    <FiEdit className="w-5 h-5" />
                  </button>
                )}
              </>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="flex items-center gap-2">
                <h3 className="font-medium text-gray-700">Author:</h3>
                <button 
                  onClick={handleAuthorClick}
                  className="text-blue-600 hover:text-blue-800"
                >
                  {initialWorkOrder.createdBy?.displayName || initialWorkOrder.createdBy?.username || 'N/A'}
                </button>
                <button
                  onClick={() => setShowViewersModal(true)}
                  className="flex items-center gap-1 text-gray-500 hover:text-gray-700 ml-2"
                  title="View viewers"
                >
                  <FiEye className="w-4 h-4" />
                  <span className="text-sm">({initialWorkOrder.viewedBy?.length || 0})</span>
                </button>
              </div>
            </div>
          </div>

          {/* Comments Section */}
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-medium text-gray-700">Comments</h3>
              {comments.length > 3 && (
                <button
                  onClick={() => setShowAllComments(!showAllComments)}
                  className="text-blue-500 hover:text-blue-600 text-sm"
                >
                  {showAllComments 
                    ? 'Hide comments' 
                    : `+${hiddenCommentsCount} more`}
                </button>
              )}
            </div>
            
            {/* Comments List */}
            <div className="space-y-4 mb-4">
              {isLoadingComments ? (
                <p className="text-gray-500 text-center">Loading comments...</p>
              ) : comments.length > 0 ? (
                visibleComments.map((comment, index) => {
                  // Debug log for each comment
                  console.log('Rendering comment:', {
                    commentId: comment._id,
                    authorId: comment.author?._id,
                    currentUser: currentUser
                  });

                  return (
                    <Comment
                      key={comment._id || index}
                      comment={comment}
                      isReply={false}
                      isReplying={replyingTo?._id === comment._id}
                      newComment={newComment}
                      onReply={handleReply}
                      onAddComment={handleAddComment}
                      onCancelReply={cancelReply}
                      onCommentChange={handleCommentChange}
                      onDelete={handleDeleteComment}
                      currentUser={currentUser}
                    />
                  );
                })
              ) : (
                <p className="text-gray-500 text-center">No comments yet</p>
              )}
            </div>

            {/* Add Comment Section - only show if not replying */}
            {!replyingTo && (
              <div className="mt-4">
                <div className="flex gap-2">
                  <textarea
                    value={newComment}
                    onChange={handleCommentChange}
                    placeholder={commentPlaceholder}
                    className="flex-1 p-2 border rounded-md min-h-[80px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button
                    onClick={handleAddComment}
                    disabled={!newComment.trim()}
                    className={`px-4 py-2 rounded-md ${
                      newComment.trim()
                        ? 'bg-blue-500 text-white hover:bg-blue-600'
                        : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    }`}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex justify-end gap-3 border-t pt-4">
            <button
              onClick={() => setShowDeleteModal(true)}
              className="px-4 py-2 text-red-600 hover:text-red-700 border border-red-600 hover:bg-red-50 rounded-md transition-colors"
            >
              Delete
            </button>
            {initialWorkOrder.status === 'closed' ? (
              <button
                onClick={() => {
                  updateWorkOrder(initialWorkOrder._id, {
                    ...initialWorkOrder,
                    status: 'open'
                  }).then(() => {
                    toast.success('Work order reopened successfully');
                    if (onUpdate) onUpdate();
                    onClose();
                  }).catch(error => {
                    console.error('Failed to reopen work order:', error);
                    toast.error('Failed to reopen work order');
                  });
                }}
                className="px-4 py-2 text-green-600 hover:text-green-700 border border-green-600 hover:bg-green-50 rounded-md transition-colors"
              >
                Reopen
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (newComment.trim()) {
                      handleAddComment().then(() => {
                        handlePostponeClick();
                      });
                    } else {
                      handlePostponeClick();
                    }
                  }}
                  className={`px-4 py-2 border rounded-md transition-colors ${
                    initialWorkOrder.status === 'postponed'
                      ? 'text-green-600 hover:text-green-700 border-green-600 hover:bg-green-50'
                      : 'text-blue-600 hover:text-blue-700 border-blue-600 hover:bg-blue-50'
                  }`}
                >
                  {initialWorkOrder.status === 'postponed' ? 'Activate' : 'Postpone'}
                </button>
                <button
                  onClick={() => {
                    if (newComment.trim()) {
                      setShowCompletionModal(true);
                    } else {
                      setShowCompletionModal(true);
                    }
                  }}
                  className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-md transition-colors"
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Viewers Modal */}
      {showViewersModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[13000]">
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <button
              onClick={() => setShowViewersModal(false)}
              className="absolute top-0 right-1 text-gray-500 hover:text-gray-700 bg-white rounded-full p-0.5 shadow-lg"
            >
              <FiX className="w-4 h-4" />
            </button>

            <h2 className="text-xl font-bold mb-4">Viewers ({initialWorkOrder.viewedBy?.length || 0})</h2>
            
            <div className="space-y-2 max-h-[60vh] overflow-y-auto">
              {isLoadingViewers ? (
                <p className="text-gray-500 text-center">Loading viewers...</p>
              ) : (
                initialWorkOrder.viewedBy?.map((viewerId) => {
                  const viewer = viewerDetails[viewerId];
                  return (
                    <div key={viewerId} className="p-2 hover:bg-gray-50 rounded-lg flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">
                          {viewer ? (viewer.displayName || viewer.username) : 'Unknown User'}
                        </span>
                      </div>
                    </div>
                  );
                })
              )}
              {!initialWorkOrder.viewedBy?.length && (
                <p className="text-gray-500 text-center">No viewers yet</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[14000]">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4 text-red-600">Warning</h2>
            <p className="mb-6">This will permanently delete the work order. There will be no history of the order. Are you sure?</p>
            
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-700 border border-gray-300 hover:bg-gray-50 rounded-md transition-colors"
              >
                No
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-md transition-colors"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Work Order Completion Modal */}
      {showCompletionModal && (
        <ModalPortal>
          <WorkOrderCompletionModal
            workOrder={initialWorkOrder}
            equipment={initialWorkOrder.equipment}
            onClose={() => setShowCompletionModal(false)}
            onComplete={(updatedWorkOrder) => {
              if (onUpdate) onUpdate(updatedWorkOrder);
              onClose();
            }}
            existingComment={newComment.trim()}
          />
        </ModalPortal>
      )}

      {showContactModal && <ContactModal />}
    </div>
  );
}

export default WorkOrderModal;
