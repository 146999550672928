import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useMediaQuery } from 'react-responsive';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddDumpsterCustomerModal from './Dumpsters/AddDumpsterCustomerModal';
import EditDumpsterCustomerModal from './Dumpsters/EditDumpsterCustomerModal';
import DumpsterManagement from './Dumpsters/DumpsterManagement';
import { checkAbilityPermissions } from '../services/authService';
import { getCustomers, createCustomer, updateCustomer } from '../services/dumpsterService';
import { formatServiceFrequency } from '../utils/dateUtils';
import { calculateGrandTotal } from '../utils/calculationUtils';

function Dumpsters() {
  const { user } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const canScheduleDumpsters = checkAbilityPermissions(user, 'CanScheduleDumpsters');

  // Fetch customers
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const data = await getCustomers();
      setCustomers(data);
      setError(null);
    } catch (err) {
      setError('Failed to load customers');
      console.error('Error fetching customers:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCustomer = async (customerData) => {
    try {
      await createCustomer(customerData);
      await fetchCustomers(); // Refresh the list
      setShowAddCustomerModal(false);
    } catch (error) {
      console.error('Error creating customer:', error);
    }
  };

  const handleEditCustomer = async (customerId, customerData) => {
    try {
      console.log('Update data:', customerData); // Debug log
      if (!customerData || typeof customerData !== 'object') {
        throw new Error('Update data must be an object');
      }
      await updateCustomer(customerId, customerData);
      fetchCustomers();
      toast.success('Customer updated successfully');
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error(error.message || 'Error updating customer');
    }
  };

  const handleCustomerSave = async () => {
    try {
      await fetchCustomers(); // Refresh the list
      // Get the latest customer data after refresh
      const updatedCustomer = customers.find(c => c._id === selectedCustomer._id);
      if (updatedCustomer) {
        setSelectedCustomer(updatedCustomer);
      }
    } catch (error) {
      console.error('Error refreshing customer:', error);
      toast.error('Failed to refresh customer data');
    }
  };

  // Filter customers based on search query
  const filteredCustomers = customers.filter(customer =>
    customer.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.billingAddress?.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.contactFirstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.contactLastName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="mt-20 relative"> {/* 80px margin for navbar */}
      {/* Header Section - 50px height */}
      <div className="h-[50px] w-full bg-white border-b border-gray-200 flex items-center px-4 justify-between">
        <div className="flex items-center space-x-4">
          <h1 className="text-xl font-semibold">Dumpster Management</h1>
          {canScheduleDumpsters && (
            <div className="flex space-x-4">
              <Link
                to="/dumpsters/manage"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Manage Dumpsters
              </Link>
              <Link
                to="/dumpsters/extras"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Manage Extras
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-1 h-[calc(100vh-130px)]"> {/* Subtract navbar (80px) + header (50px) */}
        {/* Customer List Section - Fixed 400px width */}
        <div className="w-[400px] border-r border-gray-200 bg-white overflow-y-auto">
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              {canScheduleDumpsters && (
                <button
                  onClick={() => setShowAddCustomerModal(true)}
                  className="flex items-center space-x-1 px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  <MdAdd className="w-5 h-5" />
                  <span>Add Customer</span>
                </button>
              )}
              <div className="flex items-center space-x-2">
                {showSearch ? (
                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search customers..."
                      className="pl-8 pr-4 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <FiSearch className="absolute left-2 top-2 text-gray-400" />
                  </div>
                ) : (
                  <button
                    onClick={() => setShowSearch(true)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Search"
                  >
                    <FiSearch className="w-5 h-5" />
                  </button>
                )}
                <button
                  className="p-2 hover:bg-gray-100 rounded-full"
                  title="Filter"
                >
                  <FiFilter className="w-5 h-5" />
                </button>
              </div>
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
              </div>
            ) : error ? (
              <div className="text-red-600 text-center py-4">{error}</div>
            ) : (
              <div className="space-y-2">
                {filteredCustomers.map((customer) => (
                  <div
                    key={customer._id}
                    onClick={() => {
                      setSelectedCustomer(customer);
                      setShowEditCustomerModal(true);
                    }}
                    className="p-3 border rounded-lg cursor-pointer hover:bg-gray-50"
                  >
                    <div className="flex justify-between">
                      <div>
                        <div className="font-medium">{customer.companyName}</div>
                        <div className="text-sm text-gray-600">
                          {customer.billingAddress?.city}, {customer.billingAddress?.state}
                        </div>
                        <div className="text-sm text-gray-500">
                          Phone: {customer.contactPhone}
                        </div>
                      </div>
                      <div className="text-right">
                        {/* Group services by size and pickup day */}
                        <div className="text-sm">
                          {customer.services?.reduce((acc, service) => {
                            console.log('Service data:', {
                              frequency: service.frequency,
                              weekdays: service.weekdays,
                              nextServiceDate: service.nextServiceDate,
                              formattedFreq: formatServiceFrequency(service)
                            });
                            
                            const serviceDisplay = formatServiceFrequency(service);
                            const key = `${service.dumpster.dumpsterSize} YD ${serviceDisplay}`;
                            acc[key] = (acc[key] || 0) + (service.quantity || 1);
                            return acc;
                          }, {}) && 
                          Object.entries(customer.services?.reduce((acc, service) => {
                            const serviceDisplay = formatServiceFrequency(service);
                            const key = `${service.dumpster.dumpsterSize} YD ${serviceDisplay}`;
                            acc[key] = (acc[key] || 0) + (service.quantity || 1);
                            return acc;
                          }, {})).map(([sizeAndDay, count]) => (
                            <div key={sizeAndDay}>
                              {count > 1 ? `(${count}) ` : ''}{sizeAndDay}
                            </div>
                          ))}
                        </div>
                        {/* Display total monthly charges */}
                        <div className="mt-2 text-sm font-medium text-gray-900">
                          ${calculateGrandTotal(customer.services || [], customer.extras || []).toFixed(2)}
                          {customer.billingCycle === 'on_service' ? '/per' :
                           customer.billingCycle === 'weekly' ? '/wk' :
                           customer.billingCycle === 'monthly_1st' ? '/mo' :
                           customer.billingCycle === 'monthly_both' ? '/2wk' :
                           customer.billingCycle === 'quarterly' ? '/qtr' :
                           customer.billingCycle === 'yearly' ? '/yr' : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Calendar View Area - Flexible width */}
        <div className="flex-1 bg-gray-50 overflow-y-auto px-[5px]">
          <DumpsterManagement />
        </div>
      </div>

      {/* Modals */}
      <AddDumpsterCustomerModal
        isOpen={showAddCustomerModal}
        onClose={() => setShowAddCustomerModal(false)}
        onSubmit={handleAddCustomer}
      />

      <EditDumpsterCustomerModal
        isOpen={showEditCustomerModal}
        onClose={() => setShowEditCustomerModal(false)}
        customer={selectedCustomer}
        onSubmit={handleEditCustomer}
        onSave={handleCustomerSave}
      />
    </div>
  );
}

export default Dumpsters;
