import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getWorkOrders, deleteWorkOrder, getLocations, getUsers, getEquipment } from '../services/workOrderService';
import { getEquipmentTypes, getEquipmentCategories } from '../services/equipmentService';
import { checkAbilityPermissions } from '../services/authService';
import { useAuth } from '../hooks/useAuth';
import WorkOrderList from './WorkOrderList';
import WorkOrderModal from './WorkOrderModal';
import WorkOrderForm from './WorkOrderForm';
import WorkOrderFilterModal from './WorkOrderFilterModal';
import { FiFilePlus } from 'react-icons/fi';
import { FaFilter, FaSort } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import WorkOrderSortModal from './WorkOrderSortModal';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { FiPrinter } from 'react-icons/fi';
import PrintWorkOrder from './PrintWorkOrder';

function WorkOrders({ 
  initialFilters = {}, 
  sortConfig: initialSortConfig
}) {
  const { user } = useAuth();
  const location = useLocation();
  
  // Function to get the date label based on the current sort field
  const getDateLabel = (field) => {
    switch (field) {
      case 'dateCreated':
        return 'Creation Date';
      case 'dateRequested':
        return 'Requested Date';
      case 'dateCompleted':
        return 'Completion Date';
      case 'dateScheduled':
      default:
        return 'Scheduled Date';
    }
  };

  const defaultFilters = {
    status: 'open',
    type: 'All',
    category: 'All',
    equipment: 'All',
    author: 'All',
    location: 'All Locations'
  };

  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [printMode, setPrintMode] = useState(false);
  const [canPrintWorkOrders, setCanPrintWorkOrders] = useState(false);
  const [canViewAllWorkOrders, setCanViewAllWorkOrders] = useState(false);
  const [canEditAllWorkOrders, setCanEditAllWorkOrders] = useState(false);
  const [canDeleteAllWorkOrders, setCanDeleteAllWorkOrders] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentCategories, setEquipmentCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const itemsPerPage = 10;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  
  const [filters, setFilters] = useState({ ...defaultFilters, ...initialFilters });
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(initialSortConfig || {
    field: 'dateScheduled',
    direction: 'asc',
    emptyPosition: 'bottom'
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 25;
  
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [hasNonDefaultSort, setHasNonDefaultSort] = useState(false);

  useEffect(() => {
    if (initialSortConfig) {
      setSortConfig(initialSortConfig);
    }
  }, [initialSortConfig]);

  useEffect(() => {
    if (!user) return;

    // Get filters from URL if they exist
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filters');
    
    if (filterParam) {
      try {
        const urlFilters = JSON.parse(decodeURIComponent(filterParam));
        
        // Set filters
        setFilters({
          status: urlFilters.status || 'open',
          location: urlFilters.location || 'All Locations',
          type: urlFilters.type || 'All Types',
          category: urlFilters.category || 'All',
          equipment: urlFilters.equipment || 'All',
          author: urlFilters.author || (canViewAllWorkOrders ? 'All Authors' : user.id)
        });

        // Set sort configuration separately
        if (urlFilters.sortField || urlFilters.sortDirection) {
          setSortConfig({
            field: urlFilters.sortField || 'dateScheduled',
            direction: urlFilters.sortDirection || 'asc',
            emptyPosition: 'bottom'
          });
        }
      } catch (error) {
        console.error('Error parsing URL filters:', error);
      }
    }
  }, [location.search, canViewAllWorkOrders, user]);

  const fetchWorkOrders = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Only include non-default filter values
      const activeFilters = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== defaultFilters[key]) {
          activeFilters[key] = value;
        }
      });

      const params = { 
        ...activeFilters,
        page,
        limit: ITEMS_PER_PAGE 
      };
      
      // Always include status if not explicitly set
      if (!params.status) {
        params.status = 'open';
      }
      
      if (!canViewAllWorkOrders) {
        delete params.author;
      }
      
      console.log('Fetching work orders with params:', params);
      
      const data = await getWorkOrders(params);
      
      if (Array.isArray(data)) {
        setWorkOrders(prev => page === 1 ? data : [...prev, ...data]);
        setHasMore(data.length === ITEMS_PER_PAGE);
      } else {
        throw new Error('Invalid data format received');
      }
      setError('');
    } catch (err) {
      console.error('Error fetching work orders:', err);
      setError('Failed to fetch work orders.');
    } finally {
      setIsLoading(false);
    }
  }, [filters, page, canViewAllWorkOrders]);

  useEffect(() => {
    fetchWorkOrders();
    fetchLocations();
    fetchAuthors();
    fetchEquipment();
    fetchEquipmentTypes();
    fetchEquipmentCategories();
    checkPermissions();
  }, [fetchWorkOrders]);

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      setPage(prev => prev + 1);
    }
  }, [inView, hasMore, isLoading]);

  useEffect(() => {
    setPage(1);
    setWorkOrders([]);
  }, [filters]);

  const fetchLocations = async () => {
    try {
      const locations = await getLocations();
      setLocationList(locations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError('Failed to fetch locations.');
    }
  };

  const fetchAuthors = async () => {
    try {
      const authorsResponse = await getUsers();
      if (authorsResponse.success && Array.isArray(authorsResponse.data)) {
        setAuthorList(authorsResponse.data);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (err) {
      console.error('Error fetching authors:', err);
      setError('Failed to fetch authors.');
    }
  };

  const fetchEquipment = async () => {
    try {
      const equipment = await getEquipment();
      setEquipmentList(equipment);
    } catch (error) {
      console.error('Error fetching equipment:', error);
    }
  };

  const fetchEquipmentTypes = async () => {
    try {
      const types = await getEquipmentTypes();
      setEquipmentTypes(types);
    } catch (error) {
      console.error('Error fetching equipment types:', error);
    }
  };

  const fetchEquipmentCategories = async () => {
    try {
      const categories = await getEquipmentCategories();
      setEquipmentCategories(categories);
    } catch (error) {
      console.error('Error fetching equipment categories:', error);
    }
  };

  const checkPermissions = async () => {
    try {
      const viewAllPermission = await checkAbilityPermissions('CanViewAllWorkOrders');
      setCanViewAllWorkOrders(viewAllPermission);
      const editPermission = await checkAbilityPermissions('CanEditAllWorkOrders');
      setCanEditAllWorkOrders(editPermission);
      const deletePermission = await checkAbilityPermissions('CanDeleteAllWorkOrders');
      setCanDeleteAllWorkOrders(deletePermission);
      const printPermission = await checkAbilityPermissions('CanPrintWorkOrders');
      setCanPrintWorkOrders(printPermission);
    } catch (error) {
      console.error('Error checking permissions:', error);
      setError('Error checking permissions.');
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(1);
  };

  const handleDeleteWorkOrder = async (id) => {
    const workOrder = workOrders.find(order => order._id === id);
    if (window.confirm('Are you sure you want to delete this work order?')) {
      if (workOrder && (workOrder.createdBy._id === user.id || canDeleteAllWorkOrders)) {
        try {
          await deleteWorkOrder(id);
          fetchWorkOrders();
          setSelectedWorkOrder(null);
        } catch (err) {
          setError('Failed to delete work order.');
        }
      } else {
        setError('You do not have permission to delete this work order.');
      }
    }
  };

  const handleFilterApply = async (newFilters) => {
    console.log('Applying new filters:', newFilters);
    
    // Ensure all filter fields are present
    const completeFilters = {
      ...defaultFilters,  // Start with defaults
      ...newFilters      // Override with new values
    };
    
    // Reset work orders and page before applying new filters
    setWorkOrders([]);
    setPage(1);
    setFilters(completeFilters);
    
    // Check if any filter is active
    const isFilterActive = Object.keys(completeFilters).some(key => 
      completeFilters[key] !== defaultFilters[key]
    );
    
    setActiveFilter(isFilterActive);

    try {
      setIsLoading(true);
      const params = { 
        ...completeFilters,
        page: 1,
        limit: ITEMS_PER_PAGE 
      };
      
      if (!canViewAllWorkOrders) {
        delete params.author;
      }
      
      const data = await getWorkOrders(params);
      
      if (Array.isArray(data)) {
        setWorkOrders(data);
        setHasMore(data.length === ITEMS_PER_PAGE);
      } else {
        throw new Error('Invalid data format received');
      }
      setError('');
    } catch (err) {
      console.error('Error fetching work orders:', err);
      setError('Failed to fetch work orders.');
    } finally {
      setIsLoading(false);
      setShowFilterModal(false);
    }
  };

  const handleFilterReset = async () => {
    console.log('Resetting filters to:', defaultFilters);
    
    // Reset work orders and page
    setWorkOrders([]);
    setPage(1);
    setFilters(defaultFilters);  // Use the defaultFilters object
    setActiveFilter(false);

    try {
      setIsLoading(true);
      const params = { 
        ...defaultFilters,  // Use defaultFilters here too
        page: 1,
        limit: ITEMS_PER_PAGE 
      };
      
      if (!canViewAllWorkOrders) {
        delete params.author;
      }
      
      const data = await getWorkOrders(params);
      
      if (Array.isArray(data)) {
        setWorkOrders(data);
        setHasMore(data.length === ITEMS_PER_PAGE);
      } else {
        throw new Error('Invalid data format received');
      }
      setError('');
    } catch (err) {
      console.error('Error fetching work orders:', err);
      setError('Failed to fetch work orders.');
    } finally {
      setIsLoading(false);
      setShowFilterModal(false);
    }
  };

  const handleWorkOrderClick = (workOrder) => {
    console.log('Work order clicked:', workOrder);
    setSelectedWorkOrder(workOrder);
    if (isMobile) {
      setShowWorkOrderModal(true);
    }
  };

  const handleWorkOrderUpdate = () => {
    fetchWorkOrders();
  };

  const closeWorkOrderModal = () => {
    setShowWorkOrderModal(false);
    setSelectedWorkOrder(null);
  };

  const handleCloseModal = () => {
    setShowWorkOrderModal(false);
    setSelectedWorkOrder(null);
    // Optionally refresh the work orders list to ensure data consistency
    fetchWorkOrders();
  };

  const handleSort = (workOrders) => {
    return [...workOrders].sort((a, b) => {
      const aValue = sortConfig.field === 'dateCreated' ? a.createdAt : a[sortConfig.field];
      const bValue = sortConfig.field === 'dateCreated' ? b.createdAt : b[sortConfig.field];
      
      // Handle empty dates based on emptyPosition
      if (!aValue && !bValue) return 0;
      if (!aValue) return sortConfig.emptyPosition === 'top' ? -1 : 1;
      if (!bValue) return sortConfig.emptyPosition === 'top' ? 1 : -1;
      
      // Sort by date
      const comparison = new Date(aValue) - new Date(bValue);
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });
  };

  const handleSortApply = (sortConfig) => {
    setSortConfig(sortConfig);
    setShowSortModal(false);
  };

  // Separate checks for filters and sort
  useEffect(() => {
    const defaultFilters = {
      status: 'open',
      location: 'All Locations',
      type: 'All Types',
      category: 'All',
      equipment: 'All',
      author: canViewAllWorkOrders ? 'All Authors' : user.id
    };

    const hasNonDefaultFilters = Object.keys(filters).some(key => 
      filters[key] !== defaultFilters[key] && defaultFilters.hasOwnProperty(key)
    );

    setHasActiveFilters(hasNonDefaultFilters);
  }, [filters, canViewAllWorkOrders, user]);

  useEffect(() => {
    const defaultSort = {
      field: 'dateScheduled',
      direction: 'asc',
      emptyPosition: 'bottom'
    };

    const sortingChanged = 
      sortConfig.field !== defaultSort.field || 
      sortConfig.direction !== defaultSort.direction || 
      sortConfig.emptyPosition !== defaultSort.emptyPosition;

    setHasNonDefaultSort(sortingChanged);
  }, [sortConfig]);

  // Add this computed value for filtered work orders
  const filteredWorkOrders = useMemo(() => {
    if (!Array.isArray(workOrders)) return [];
    
    return workOrders.filter(order => {
      // Status filter
      if (filters.status !== 'All' && order.status !== filters.status) {
        return false;
      }

      // Type filter
      if (filters.type !== 'All' && order.type?._id !== filters.type) {
        return false;
      }

      // Category filter
      if (filters.category !== 'All' && order.category?._id !== filters.category) {
        return false;
      }

      // Equipment filter
      if (filters.equipment !== 'All' && order.equipment?.ID !== filters.equipment) {
        return false;
      }

      // Location filter
      if (filters.location !== 'All Locations' && order.location?.name !== filters.location) {
        return false;
      }

      // Author filter
      if (canViewAllWorkOrders && filters.author !== 'All' && order.createdBy?._id !== filters.author) {
        return false;
      }

      return true;
    });
  }, [workOrders, filters, canViewAllWorkOrders]);

  // Add a function to check if filters are at default values
  const areFiltersDefault = useMemo(() => {
    return Object.entries(filters).every(([key, value]) => value === defaultFilters[key]);
  }, [filters]);

  const handleWorkOrderAdded = () => {
    setShowAddForm(false);
    fetchWorkOrders();
  };

  return (
    <div className="flex flex-col h-[calc(100vh-80px)] mt-[80px]">
      <div className="flex h-full overflow-hidden">
        {/* Left section - Work Order List */}
        <div className={`flex flex-col min-w-0 ${!isMobile ? 'w-[400px]' : 'flex-1'}`}>
          {/* Controls section - always visible */}
          <div className="px-2 pt-2 pb-1 bg-white">
            <div className="flex justify-between items-center">
              <button
                className="p-1 text-green-500 hover:text-green-600 transition-colors"
                onClick={() => setShowAddForm(true)}
                title="Add Work Order"
              >
                <FiFilePlus className="w-8 h-8" />
              </button>
              
              <div className="flex gap-1">
                {canPrintWorkOrders && (
                  <button
                    onClick={() => setPrintMode(!printMode)}
                    className={`p-1 transition-colors ${
                      printMode ? 'text-green-500 hover:text-green-600' : 'text-gray-600 hover:text-gray-800'
                    }`}
                    title={printMode ? 'Exit Print Mode' : 'Enter Print Mode'}
                  >
                    <FiPrinter className="w-7 h-7" />
                  </button>
                )}
                <button
                  className={`p-1 transition-colors ${
                    areFiltersDefault 
                      ? 'text-gray-600 hover:text-gray-800' 
                      : 'text-blue-500 hover:text-blue-600'
                  }`}
                  onClick={() => setShowFilterModal(true)}
                  title="Filter Work Orders"
                >
                  <FaFilter className="w-7 h-7" />
                </button>

                <button
                  className={`p-1 transition-colors ${
                    hasNonDefaultSort ? 'text-red-500' : 'text-gray-600 hover:text-gray-800'
                  }`}
                  onClick={() => setShowSortModal(true)}
                  title="Sort Work Orders"
                >
                  <FaSort className="w-7 h-7" />
                </button>
              </div>
            </div>
          </div>

          {/* List Header - Fixed */}
          <div className="px-2 py-1 bg-white border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-bold">Work Orders</h2>
              <span className="text-sm text-gray-600">{getDateLabel(sortConfig.field)}</span>
            </div>
          </div>

          {error && (
            <p className="text-red-500 text-center my-4">{error}</p>
          )}

          {/* Work Order List section - scrollable */}
          {isLoading ? (
            <p className="text-center p-4">Loading work orders...</p>
          ) : (
            <div className="flex-1 overflow-y-auto">
              <WorkOrderList
                workOrders={handleSort(filteredWorkOrders)} 
                onSelectWorkOrder={handleWorkOrderClick}
                selectedWorkOrder={selectedWorkOrder}
                sortConfig={sortConfig}
                loadMoreRef={ref}
                hasMore={hasMore}
                isLoading={isLoading}
                currentUser={user}
                printMode={printMode}
              />
            </div>
          )}
        </div>

        {/* Right section - Work Order Modal */}
        {!isMobile && selectedWorkOrder && (
          <div className="flex-1 border-l border-gray-200 flex flex-col relative z-[9000]">
            <div className="flex-1 overflow-y-auto pt-8">
              {printMode ? (
                <PrintWorkOrder
                  workOrders={workOrders}
                  onClose={() => setSelectedWorkOrder(null)}
                />
              ) : (
                <WorkOrderModal
                  workOrder={selectedWorkOrder}
                  onClose={() => setSelectedWorkOrder(null)}
                  onUpdate={handleWorkOrderUpdate}
                  isInlineDisplay={true}
                  currentUser={user}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {isMobile && showWorkOrderModal && selectedWorkOrder && (
        <WorkOrderModal
          workOrder={selectedWorkOrder}
          onClose={handleCloseModal}
          onUpdate={handleWorkOrderUpdate}
          isInlineDisplay={false}
          currentUser={user}
        />
      )}

      {showAddForm && (
        <WorkOrderForm
          onClose={() => setShowAddForm(false)}
          onWorkOrderAdded={handleWorkOrderAdded}
        />
      )}

      {showFilterModal && (
        <WorkOrderFilterModal
          filters={filters}
          onApply={handleFilterApply}
          onReset={handleFilterReset}
          onClose={() => {
            console.log('Closing filter modal');
            setShowFilterModal(false);
          }}
          locationList={locationList}
          authorList={authorList}
          canViewAllWorkOrders={canViewAllWorkOrders}
          equipmentList={equipmentList}
          equipmentTypes={equipmentTypes}
          equipmentCategories={equipmentCategories}
        />
      )}

      {showSortModal && (
        <WorkOrderSortModal
          onApply={handleSortApply}
          onClose={() => setShowSortModal(false)}
          currentSort={sortConfig}
        />
      )}
    </div>
  );
}

export default WorkOrders;
