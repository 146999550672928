import { startOfWeek, endOfWeek, format, addWeeks } from 'date-fns';
import axiosInstance from './api';

export const getServiceSchedule = async (date = new Date(), weekRange = 1) => {
    try {
        const startDate = format(startOfWeek(date), 'yyyy-MM-dd');
        const endDate = format(endOfWeek(addWeeks(date, weekRange - 1)), 'yyyy-MM-dd');
        
        const response = await axiosInstance.get('/dumpsters/services/schedule', {
            params: { startDate, endDate }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching service schedule:', error);
        throw error;
    }
};

export const getServices = async () => {
    try {
        const response = await axiosInstance.get('/dumpsters/services');
        return response.data;
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;
    }
};

export const getDumpsterServices = async (customerId) => {
    try {
        const response = await axiosInstance.get(`/dumpsters/services/customer/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching customer services:', error);
        throw error;
    }
};

export const updateService = async (serviceId, serviceData) => {
    try {
        const response = await axiosInstance.put(`/dumpsters/services/${serviceId}`, serviceData);
        return response.data;
    } catch (error) {
        console.error('Error updating service:', error);
        throw error;
    }
};

export const deleteService = async (serviceId) => {
    try {
        const response = await axiosInstance.delete(`/dumpsters/services/${serviceId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting service:', error);
        throw error;
    }
};

export const createService = async (serviceData) => {
    try {
        const response = await axiosInstance.post('/dumpsters/services', serviceData);
        return response.data;
    } catch (error) {
        console.error('Error creating service:', error);
        throw error;
    }
};
