import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login as loginService } from '../services/authService';
import { useAuth } from '../hooks/useAuth';
import ForgotCredentialsModal from './ForgotCredentialsModal';

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);
  const [showForgotLink, setShowForgotLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({ 
      ...credentials, 
      [e.target.name]: e.target.value.trim() 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const trimmedCredentials = {
        username: credentials.username.trim(),
        password: credentials.password.trim()
      };
      
      const loginResponse = await loginService(trimmedCredentials.username, trimmedCredentials.password);
      
      if (!loginResponse.token || !loginResponse.user) {
        setError('Login failed. Please check your credentials.');
        setShowForgotLink(true);
        return;
      }

      localStorage.setItem('token', loginResponse.token);
      localStorage.setItem('user', JSON.stringify(loginResponse.user));
      login(loginResponse.user);
      navigate('/dashboard');
    } catch (err) {
      setShowForgotLink(true);
      console.error('Login error:', err);
      
      // Handle specific error messages from the backend
      const errorMessage = err.response?.data?.message || 'An error occurred during login.';
      setError(errorMessage);

      // Show forgot link for authentication errors
      if (err.response?.status === 401) {
        setShowForgotLink(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-[100dvh] flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-sm mx-4 bg-white rounded-lg shadow-lg">
        <div className="bg-primary px-6 py-3">
          <h2 className="text-center text-2xl font-bold text-white">
            Login
          </h2>
        </div>

        <div className="px-6 py-3">
          {error && (
            <div className="mb-2 text-red-500 text-sm text-center bg-red-50 border border-red-200 rounded-md p-2">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                value={credentials.username}
                onChange={handleChange}
                className="appearance-none block w-full px-3 py-1.5 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                value={credentials.password}
                onChange={handleChange}
                className="appearance-none block w-full px-3 py-1.5 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-1.5 px-4 border border-transparent 
                rounded-md shadow-sm text-sm font-medium text-white bg-primary 
                hover:bg-primary/90 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary transition-colors
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>

          <div className="flex flex-col items-center gap-1 mt-2">
            <Link 
              to="/register" 
              className="text-sm text-primary hover:text-primary/80 font-medium"
            >
              Don't have an account? Register here.
            </Link>

            <div className="mt-3 text-center">
              <Link to="/forgot-password" className="text-primary text-decoration-none">
                Forgot Password?
              </Link>
            </div>

            {showForgotLink && (
              <div className="mt-3 text-center">
                <button 
                  onClick={() => setIsForgotModalOpen(true)}
                  className="btn btn-link text-primary"
                >
                  Forgot Username?
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ForgotCredentialsModal 
        isOpen={isForgotModalOpen} 
        onClose={() => setIsForgotModalOpen(false)} 
      />
    </div>
  );
}

export default Login;