import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

function AddDumpsterCustomerModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    contactEmail: '',
    billingAddress: {
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    salesContact: '',
    contractLength: '',
    contractStartDate: '',
    contractEndDate: '',
    billingCycle: 'on_service',
    autoPay: false,
    active: true,
    taxExempt: false,
    creditHold: false,
    notes: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      if (!formData.companyName) {
        toast.error('Please fill in company name');
        return;
      }

      // Validate billing address fields
      const { street, city, state, zip } = formData.billingAddress;
      if (!street || !city || !state || !zip) {
        toast.error('Please fill in all billing address fields');
        return;
      }

      // Calculate contract end date if both start date and length are provided
      if (formData.contractStartDate && formData.contractLength) {
        const startDate = new Date(formData.contractStartDate);
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + parseInt(formData.contractLength));
        formData.contractEndDate = endDate.toISOString().split('T')[0];
      }

      await onSubmit(formData);
      toast.success('Customer created successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to create customer: ' + (error.response?.data?.message || error.message));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Add New Customer</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <MdClose className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Company Information */}
          <div className="space-y-4">
            <h3 className="font-medium">Company Information</h3>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Company Name *"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Contact Information */}
          <div className="space-y-4">
            <h3 className="font-medium">Contact Information</h3>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  name="contactFirstName"
                  value={formData.contactFirstName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="contactLastName"
                  value={formData.contactLastName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Billing Address */}
          <div className="space-y-4">
            <h3 className="font-medium">Billing Address</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                name="billingAddress.street"
                value={formData.billingAddress.street}
                onChange={handleChange}
                placeholder="Street Address *"
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="text"
                name="billingAddress.city"
                value={formData.billingAddress.city}
                onChange={handleChange}
                placeholder="City *"
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="text"
                name="billingAddress.state"
                value={formData.billingAddress.state}
                onChange={handleChange}
                placeholder="State *"
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
                required
              />
              <div className="block">
                <label className="block text-sm font-medium text-gray-700">ZIP Code</label>
                <input
                  type="text"
                  name="billingAddress.zip"
                  value={formData.billingAddress.zip}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* Billing Information */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Billing Cycle</label>
            <select
              name="billingCycle"
              value={formData.billingCycle}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            >
              <option value="on_service">On Service</option>
              <option value="weekly">Weekly</option>
              <option value="monthly_1st">Monthly (1st)</option>
              <option value="monthly_both">Monthly (1st and 15th)</option>
              <option value="quarterly">Quarterly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          {/* Contract Information */}
          <div className="space-y-4">
            <h3 className="font-medium">Contract Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                name="salesContact"
                value={formData.salesContact}
                onChange={handleChange}
                placeholder="Sales Contact"
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="number"
                name="contractLength"
                value={formData.contractLength}
                onChange={handleChange}
                placeholder="Contract Length (months)"
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
                min="1"
              />
              <input
                type="date"
                name="contractStartDate"
                value={formData.contractStartDate}
                onChange={handleChange}
                className="p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Additional Settings */}
          <div className="space-y-4">
            <h3 className="font-medium">Additional Settings</h3>
            <div className="flex flex-wrap gap-4">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="autoPay"
                  checked={formData.autoPay}
                  onChange={handleChange}
                  className="form-checkbox"
                />
                <span>Auto Pay</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="taxExempt"
                  checked={formData.taxExempt}
                  onChange={handleChange}
                  className="form-checkbox"
                />
                <span>Tax Exempt</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="creditHold"
                  checked={formData.creditHold}
                  onChange={handleChange}
                  className="form-checkbox"
                />
                <span>Credit Hold</span>
              </label>
            </div>
          </div>

          {/* Notes */}
          <div className="space-y-2">
            <h3 className="font-medium">Notes</h3>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Additional notes..."
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 h-24"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end pt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Create Customer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddDumpsterCustomerModal;
