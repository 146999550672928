import React, { useState } from 'react';
import { FiCheck, FiX, FiArrowDown } from 'react-icons/fi';
import '../styles/WorkOrderSortModal.css';

function WorkOrderSortModal({ onApply, onClose, currentSort }) {
  
    const [sortConfig, setSortConfig] = useState(currentSort || {
        field: 'dateScheduled',
        direction: 'asc',
        emptyPosition: 'bottom'
      });
    
      const sortOptions = [
        { value: 'equipment.name', label: 'Equipment Name' },
        { value: 'dateCreated', label: 'Creation Date' },
        { value: 'dateRequested', label: 'Requested Date' },
        { value: 'dateCompleted', label: 'Completion Date' },
        { value: 'dateScheduled', label: 'Scheduled Date' }
      ];

      const isDateField = (field) => {
        return ['dateCreated', 'dateRequested', 'dateCompleted', 'dateScheduled'].includes(field);
      };
    
      const handleChange = (field, value) => {
        setSortConfig(prev => ({ ...prev, [field]: value }));
      };
    
      const toggleDirection = () => {
        setSortConfig(prev => ({
          ...prev,
          direction: prev.direction === 'desc' ? 'asc' : 'desc'
        }));
      };
    
      const defaultSort = {
        field: 'dateScheduled',
        direction: 'asc',
        emptyPosition: 'bottom'
      };
    
      const handleReset = () => {
        setSortConfig(defaultSort);
      };
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[12000]">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Sort Work Orders</h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Sort by
                </label>
                <select
                  value={sortConfig.field}
                  onChange={(e) => handleChange('field', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  {sortOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Order
                </label>
                <button
                  type="button"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-left flex items-center justify-between hover:bg-gray-50"
                  onClick={toggleDirection}
                >
                  <span>
                    {sortConfig.field === 'equipment.name' 
                      ? (sortConfig.direction === 'asc' ? 'A to Z' : 'Z to A')
                      : (sortConfig.direction === 'asc' ? 'Old to New' : 'New to Old')}
                  </span>
                  <FiArrowDown
                    className={`transform transition-transform ${
                      sortConfig.direction === 'desc' ? 'rotate-180' : ''
                    }`}
                  />
                </button>
              </div>

              {isDateField(sortConfig.field) && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Empty Values
                  </label>
                  <button
                    type="button"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-left flex items-center justify-between hover:bg-gray-50"
                    onClick={() => handleChange('emptyPosition', sortConfig.emptyPosition === 'bottom' ? 'top' : 'bottom')}
                  >
                    <span>Show Empty Values at {sortConfig.emptyPosition === 'bottom' ? 'Bottom' : 'Top'}</span>
                  </button>
                </div>
              )}

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={handleReset}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Reset
                </button>
                <button
                  onClick={() => onApply(sortConfig)}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      );
}

export default WorkOrderSortModal;