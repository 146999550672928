// Calculate the total base service charges
export const calculateTotalServiceCharges = (services) => {
    return services.reduce((total, service) => {
        return total + (service.rate || 0);
    }, 0);
};

// Calculate the value of a single extra
export const calculateExtraValue = (baseTotal, extra) => {
    const value = extra.customValue || extra.extra.value;
    if (extra.extra.calculationType === 'percentage') {
        return (baseTotal * value) / 100;
    }
    return value;
};

// Calculate total extra charges
export const calculateTotalExtraCharges = (baseTotal, extras) => {
    return extras.reduce((total, extra) => {
        const extraValue = calculateExtraValue(baseTotal, extra);
        return total + (extra.extra.type === 'surcharge' ? extraValue : -extraValue);
    }, 0);
};

// Calculate grand total including services and extras
export const calculateGrandTotal = (services, extras) => {
    const baseTotal = calculateTotalServiceCharges(services);
    const extraCharges = calculateTotalExtraCharges(baseTotal, extras);
    return baseTotal + extraCharges;
};
