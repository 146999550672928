import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { FiX } from 'react-icons/fi';
import { useAuth } from '../../hooks/useAuth';
import { checkAbilityPermissions } from '../../services/authService';
import { getDumpsterServices, updateService } from '../../services/dumpsterServiceService';
import AddServiceModal from './AddServiceModal';
import EditServiceModal from './EditServiceModal';
import CustomerExtras from './Extras/CustomerExtras';
import { FiPlus, FiEdit2 } from 'react-icons/fi';

// US States list
const STATES = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' }
];

function EditDumpsterCustomerModal({ isOpen, onClose, customer: initialCustomer, onSubmit, onSave, onServiceUpdate }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [extrasHandlers, setExtrasHandlers] = useState(null);

    const [formData, setFormData] = useState({
        companyName: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',
        salesContact: '',
        billingCycle: '',
        rate: '',
        billingAddress: {
            street: '',
            city: '',
            state: '',
            zip: ''
        },
        notes: '',
        extras: [],
        contractLength: '',
        contractStartDate: '',
        autoPay: false,
        taxExempt: false,
        creditHold: false,
        active: false
    });

    const [services, setServices] = useState([]);
    const [isLoadingServices, setIsLoadingServices] = useState(false);

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (customer?._id) {
            fetchCustomerServices();
        }
    }, [customer?._id]); // Only depend on customer ID

    const fetchCustomerServices = async () => {
        if (!customer?._id) return;
        
        setIsLoadingServices(true);
        try {
            console.log('Fetching services for customer:', customer._id);
            const data = await getDumpsterServices(customer._id);
            console.log('Fetched services:', data);
            setServices(data);
        } catch (error) {
            console.error('Error fetching customer services:', error);
            toast.error('Failed to load customer services');
        } finally {
            setIsLoadingServices(false);
        }
    };

    // Update local state when customer prop changes
    useEffect(() => {
        if (!initialCustomer?._id) {
            onClose();
            return;
        }
        
        setCustomer(initialCustomer);
        setEditMode(false); // Reset edit mode when new customer is loaded
        setFormData({
            companyName: initialCustomer.companyName || '',
            contactFirstName: initialCustomer.contactFirstName || '',
            contactLastName: initialCustomer.contactLastName || '',
            contactPhone: initialCustomer.contactPhone || '',
            contactEmail: initialCustomer.contactEmail || '',
            salesContact: initialCustomer.salesContact || '',
            billingCycle: initialCustomer.billingCycle || '',
            rate: initialCustomer.rate || '',
            billingAddress: {
                street: initialCustomer.billingAddress?.street || '',
                city: initialCustomer.billingAddress?.city || '',
                state: initialCustomer.billingAddress?.state || '',
                zip: initialCustomer.billingAddress?.zip || ''
            },
            notes: initialCustomer.notes || '',
            extras: initialCustomer.extras || [],
            contractLength: initialCustomer.contractLength || '',
            contractStartDate: initialCustomer.contractStartDate || '',
            autoPay: initialCustomer.autoPay || false,
            taxExempt: initialCustomer.taxExempt || false,
            creditHold: initialCustomer.creditHold || false,
            active: initialCustomer.active || false
        });
    }, [initialCustomer, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            // Validate required fields
            if (!formData.companyName) {
                toast.error('Please fill in company name');
                return;
            }

            // Validate billing address fields
            const { street, city, state, zip } = formData.billingAddress;
            if (!street || !city || !state || !zip) {
                toast.error('Please fill in all billing address fields');
                return;
            }

            // Calculate contract end date if both start date and length are provided
            if (formData.contractStartDate && formData.contractLength) {
                const startDate = new Date(formData.contractStartDate);
                const endDate = new Date(startDate);
                endDate.setMonth(endDate.getMonth() + parseInt(formData.contractLength));
                formData.contractEndDate = endDate.toISOString().split('T')[0];
            }

            // Clean up empty strings for contact fields
            const updatedData = {
                ...formData,
                _id: customer._id, // Ensure customer ID is included
                contactFirstName: formData.contactFirstName || null,
                contactLastName: formData.contactLastName || null,
                contactPhone: formData.contactPhone || null,
                contactEmail: formData.contactEmail || null,
                salesContact: formData.salesContact || null
            };

            await onSubmit(customer._id, updatedData);
            setEditMode(false); // Reset edit mode after successful save
            toast.success('Customer updated successfully');
            onClose();
        } catch (error) {
            toast.error('Failed to update customer: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [section, field] = name.split('.');
            setFormData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleAddService = async (serviceData) => {
        try {
            await updateService(serviceData);
            // Close the modal first
            setShowAddServiceModal(false);
            // Update the services list and customer data
            await fetchCustomerServices();
            // If onSave exists, call it to refresh parent components
            if (onSave) {
                await onSave();
            }
            toast.success('Service added successfully');
        } catch (error) {
            console.error('Error adding service:', error);
            toast.error('Failed to add service');
        }
    };

    const handleServiceClick = (service) => {
        setSelectedService(service);
        setShowEditServiceModal(true);
    };

    const handleEditService = async (service) => {
        setSelectedService(service);
        setShowEditServiceModal(true);
    };

    const handleServiceUpdate = async (updatedService) => {
        try {
            // Make sure we preserve the service ID
            const serviceToUpdate = {
                ...updatedService,
                _id: selectedService._id // Ensure we have the correct ID
            };
            
            // Update the service
            const updatedServiceData = await updateService(serviceToUpdate._id, serviceToUpdate);
            console.log('Service updated:', updatedServiceData);
            
            // Close the modal first
            setShowEditServiceModal(false);
            setSelectedService(null);
            
            // Refresh services list
            await fetchCustomerServices();
            
            // Notify parent component to refresh calendar
            if (onServiceUpdate) {
                await onServiceUpdate(); // Wait for the calendar refresh
            }
            
            toast.success('Service updated successfully');
        } catch (error) {
            console.error('Error updating service:', error);
            toast.error('Failed to update service');
        }
    };

    const handleExtraUpdate = (data) => {
        if (data) {
            setExtrasHandlers(prev => ({
                ...prev,
                commitExtrasChanges: data.commitExtrasChanges,
                revertExtrasChanges: data.revertExtrasChanges
            }));

            // Update form data with new extras
            if (data.extras) {
                setFormData(prev => ({
                    ...prev,
                    extras: data.extras
                }));
            }
        }
    };

    const handleSave = async () => {
        try {
            // Validate required fields
            if (!formData.companyName || !formData.contactPhone) {
                toast.error('Please fill in all required fields');
                return;
            }

            // Prepare customer data
            const customerData = {
                companyName: formData.companyName,
                contactFirstName: formData.contactFirstName,
                contactLastName: formData.contactLastName,
                contactPhone: formData.contactPhone,
                contactEmail: formData.contactEmail,
                salesContact: formData.salesContact,
                billingAddress: {
                    street: formData.billingAddress.street,
                    city: formData.billingAddress.city,
                    state: formData.billingAddress.state,
                    zip: formData.billingAddress.zip
                },
                notes: formData.notes,
                extras: formData.extras || [], // Ensure extras is always an array
                contractLength: formData.contractLength,
                contractStartDate: formData.contractStartDate,
                autoPay: formData.autoPay,
                paperless: formData.paperless,
                creditHold: formData.creditHold,
                active: formData.active
            };

            // Add or update customer
            if (customer) {
                await onSave(customer._id, customerData);
            } else {
                await onSave(customerData);
            }

            onClose();
            toast.success(`Customer ${customer ? 'updated' : 'added'} successfully`);
        } catch (error) {
            console.error('Error saving customer:', error);
            toast.error(error.response?.data?.message || 'Error saving customer');
        }
    };

    const handleCancel = () => {
        // Revert any pending extras changes
        if (extrasHandlers?.revertExtrasChanges) {
            extrasHandlers.revertExtrasChanges();
        }
        setEditMode(false); // Reset edit mode when cancelling
        onClose();
    };

    const totalMonthlyServices = useMemo(() => {
        if (!customer?.services?.length) return 0;
        
        // Calculate base total from regular services
        let total = customer.services.reduce((acc, service) => {
            return acc + (service.rate || 0);
        }, 0);

        // Add any extras
        if (customer.extras?.length) {
            total += customer.extras.reduce((acc, extra) => {
                return acc + (extra.rate || 0);
            }, 0);
        }

        return total;
    }, [customer?.services, customer?.extras]);

    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [showEditServiceModal, setShowEditServiceModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const getServiceStatus = (service) => {
        if (!service) return null;

        if (service.onHold) {
            return {
                text: "Service is on hold",
                color: "text-red-600"
            };
        }

        // Calculate next service date based on frequency
        const getNextServiceDate = (service) => {
            const today = new Date();
            // If service is postponed, use resume date as the starting point
            let nextDate = service.isPostponed && service.resumeDate ? 
                new Date(service.resumeDate) : new Date(today);

            switch (service.frequency?.toLowerCase()) {
                case 'weekly':
                    if (service.weekdays && service.weekdays.length > 0) {
                        let daysToAdd = 7;
                        const startDay = nextDate.getDay();
                        const weekdayIndices = service.weekdays.map(day => 
                            ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(day.toLowerCase())
                        );
                        
                        // Find the next service day after our start date
                        weekdayIndices.forEach(weekday => {
                            const diff = weekday - startDay;
                            // If diff is negative or zero, add 7 to get to next week
                            const adjustedDiff = diff <= 0 ? diff + 7 : diff;
                            if (adjustedDiff < daysToAdd) daysToAdd = adjustedDiff;
                        });
                        
                        nextDate.setDate(nextDate.getDate() + daysToAdd);
                    }
                    break;

                case 'biweekly':
                    if (service.biweeklyDay) {
                        const targetDayIndex = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
                            .indexOf(service.biweeklyDay.toLowerCase());
                        const startDayIndex = nextDate.getDay();
                        let daysToAdd = (targetDayIndex + 7 - startDayIndex) % 7;
                        if (daysToAdd === 0) daysToAdd = 7; // If it's the same day, move to next week
                        nextDate.setDate(nextDate.getDate() + daysToAdd);
                    }
                    break;

                case 'monthly':
                    if (service.monthlyWeek && service.monthlyDay) {
                        const monthlyDayIndex = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
                            .indexOf(service.monthlyDay.toLowerCase());
                        const weekIndex = ['first', 'second', 'third', 'fourth', 'last']
                            .indexOf(service.monthlyWeek.toLowerCase());
                        
                        let currentMonth = nextDate.getMonth();
                        let currentYear = nextDate.getFullYear();
                        
                        const findWeekdayOccurrence = (year, month, dayIndex, weekNum) => {
                            let date = new Date(year, month, 1);
                            let count = 0;
                            
                            if (weekNum === 4) { // "Last"
                                date = new Date(year, month + 1, 0);
                                while (date.getDay() !== dayIndex) {
                                    date.setDate(date.getDate() - 1);
                                }
                                return date;
                            }
                            
                            while (count <= weekNum) {
                                if (date.getDay() === dayIndex) {
                                    count++;
                                    if (count > weekNum) break;
                                }
                                date.setDate(date.getDate() + 1);
                            }
                            return date;
                        };
                        
                        let targetDate = findWeekdayOccurrence(currentYear, currentMonth, monthlyDayIndex, weekIndex);
                        
                        // If target date is before our start date, move to next month
                        while (targetDate < nextDate) {
                            currentMonth++;
                            if (currentMonth > 11) {
                                currentMonth = 0;
                                currentYear++;
                            }
                            targetDate = findWeekdayOccurrence(currentYear, currentMonth, monthlyDayIndex, weekIndex);
                        }
                        
                        nextDate = targetDate;
                    }
                    break;

                default:
                    return null;
            }

            return nextDate;
        };

        const nextDate = getNextServiceDate(service);
        if (!nextDate) return null;

        // If service is postponed, show both dates
        if (service.isPostponed && service.resumeDate) {
            const resumeDate = new Date(service.resumeDate);
            return {
                text: `Postponed until ${resumeDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}, next service ${nextDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`,
                color: "text-blue-600"
            };
        }

        return {
            text: `Next service ${nextDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`,
            color: "text-green-600"
        };
    };

    const ServicesTabContent = () => (
        <div className="space-y-4">
            {isLoadingServices ? (
                <div className="flex justify-center items-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                </div>
            ) : services.length > 0 ? (
                <div className="space-y-4">
                    {services.map((service) => {
                        const status = getServiceStatus(service);
                        const getBackgroundColor = (type) => {
                            const lowerType = type?.toLowerCase() || '';
                            if (lowerType.includes('recycle')) {
                                return 'bg-green-50';
                            } else if (lowerType.includes('trash')) {
                                return 'bg-yellow-900 bg-opacity-10';
                            }
                            return 'bg-gray-50';
                        };
                        return (
                            <div key={service._id} className={`${getBackgroundColor(service.type)} p-4 rounded-lg`}>
                                <div className="flex justify-between items-start">
                                    <div>
                                        <h4 className="text-lg font-medium">
                                            {service.type} Service - {service.frequency}
                                        </h4>
                                        <p className="text-sm text-gray-600">
                                            {service.dumpster?.dumpsterSize} yard {service.dumpster?.type}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Quantity: {service.quantity}
                                        </p>
                                        {service.frequency === 'weekly' && service.weekdays?.length > 0 && (
                                            <p className="text-sm text-gray-600">
                                                Days: {service.weekdays.join(', ')}
                                            </p>
                                        )}
                                        {service.frequency === 'biweekly' && service.biweeklyDay && (
                                            <p className="text-sm text-gray-600">
                                                Day: {service.biweeklyDay}
                                            </p>
                                        )}
                                        {service.frequency === 'monthly' && (
                                            <p className="text-sm text-gray-600">
                                                {service.monthlyWeek} {service.monthlyDay} of each month
                                            </p>
                                        )}
                                        {service.rate && (
                                            <p className="text-sm text-gray-600">
                                                Rate: ${service.rate}
                                            </p>
                                        )}
                                        {status && (
                                            <p className={`text-sm font-medium mt-2 ${status.color}`}>
                                                {status.text}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleEditService(service)}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <FiEdit2 className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="text-center text-gray-500">No services found</p>
            )}
        </div>
    );

    if (!isOpen) return null;

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[10000]" onClose={() => {
                setEditMode(false);
                onClose();
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[10000]" />
                </Transition.Child>

                <div className="fixed inset-0 z-[10000] overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center mb-4">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Customer Information
                                    </Dialog.Title>
                                    <button
                                        onClick={onClose}
                                        className="p-1 rounded-full hover:bg-gray-100"
                                    >
                                        <FiX className="w-6 h-6" />
                                    </button>
                                </div>

                                <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
                                    {/* Company Information */}
                                    <div>
                                        {editMode ? (
                                            <>
                                                <h4 className="text-md font-medium mb-2">Company Information</h4>
                                                <div className="grid grid-cols-1 gap-4">
                                                    <div>
                                                        <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                                                            Company Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="companyName"
                                                            id="companyName"
                                                            value={formData.companyName}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <h4 className="text-lg font-semibold">{formData.companyName}</h4>
                                                    <div className="mt-2 text-sm text-gray-600">
                                                        <p>{formData.contactFirstName} {formData.contactLastName}</p>
                                                        <p>{formData.contactPhone}</p>
                                                        <p>{formData.contactEmail}</p>
                                                        <p className="mt-2">
                                                            {formData.billingAddress.street}<br />
                                                            {formData.billingAddress.city}, {formData.billingAddress.state} {formData.billingAddress.zip}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-right">
                                                    <button
                                                        type="button"
                                                        onClick={() => setEditMode(true)}
                                                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    >
                                                        <FiEdit2 className="w-4 h-4 mr-1" />
                                                        Edit
                                                    </button>
                                                    <div className="text-sm text-gray-600 mt-4">
                                                        {formData.billingCycle && (
                                                            <p>
                                                                <span className="font-medium">Billing Cycle:</span>{' '}
                                                                {formData.billingCycle.charAt(0).toUpperCase() + formData.billingCycle.slice(1)}
                                                            </p>
                                                        )}
                                                        {formData.contractLength && (
                                                            <p>
                                                                <span className="font-medium">Contract Length:</span>{' '}
                                                                {formData.contractLength} months
                                                            </p>
                                                        )}
                                                        {formData.contractStartDate && (
                                                            <p>
                                                                <span className="font-medium">Contract Start:</span>{' '}
                                                                {new Date(formData.contractStartDate).toLocaleDateString()}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Contact Information */}
                                    {editMode && (
                                        <>
                                            <div>
                                                <h4 className="text-md font-medium mb-2">Contact Information</h4>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label htmlFor="contactFirstName" className="block text-sm font-medium text-gray-700">
                                                            First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="contactFirstName"
                                                            id="contactFirstName"
                                                            value={formData.contactFirstName}
                                                            onChange={handleChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="contactLastName" className="block text-sm font-medium text-gray-700">
                                                            Last Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="contactLastName"
                                                            id="contactLastName"
                                                            value={formData.contactLastName}
                                                            onChange={handleChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700">
                                                            Phone
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            name="contactPhone"
                                                            id="contactPhone"
                                                            value={formData.contactPhone}
                                                            onChange={handleChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="contactEmail"
                                                            id="contactEmail"
                                                            value={formData.contactEmail}
                                                            onChange={handleChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Billing Address */}
                                            <div>
                                                <h4 className="text-md font-medium mb-2">Billing Address</h4>
                                                <div className="grid grid-cols-1 gap-4">
                                                    <div>
                                                        <label htmlFor="billingAddress.street" className="block text-sm font-medium text-gray-700">
                                                            Street Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="billingAddress.street"
                                                            id="billingAddress.street"
                                                            value={formData.billingAddress.street}
                                                            onChange={handleChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div>
                                                            <label htmlFor="billingAddress.city" className="block text-sm font-medium text-gray-700">
                                                                City
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="billingAddress.city"
                                                                id="billingAddress.city"
                                                                value={formData.billingAddress.city}
                                                                onChange={handleChange}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="billingAddress.state" className="block text-sm font-medium text-gray-700">
                                                                State
                                                            </label>
                                                            <select
                                                                name="billingAddress.state"
                                                                id="billingAddress.state"
                                                                value={formData.billingAddress.state}
                                                                onChange={handleChange}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            >
                                                                <option value="">Select State</option>
                                                                {STATES.map(state => (
                                                                    <option key={state.abbreviation} value={state.abbreviation}>
                                                                        {state.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="billingAddress.zip" className="block text-sm font-medium text-gray-700">
                                                                ZIP Code
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="billingAddress.zip"
                                                                id="billingAddress.zip"
                                                                value={formData.billingAddress.zip}
                                                                onChange={handleChange}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {/* Billing Information */}
                                    {editMode && (
                                        <div>
                                            <h4 className="text-md font-medium mb-2">Billing Information</h4>
                                            <div className="grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="billingCycle" className="block text-sm font-medium text-gray-700">
                                                        Billing Cycle
                                                    </label>
                                                    <select
                                                        name="billingCycle"
                                                        id="billingCycle"
                                                        value={formData.billingCycle}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="">Select a billing cycle</option>
                                                        <option value="weekly">Weekly</option>
                                                        <option value="biweekly">Bi-Weekly</option>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="quarterly">Quarterly</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="contractLength" className="block text-sm font-medium text-gray-700">
                                                        Contract Length (months)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="contractLength"
                                                        id="contractLength"
                                                        value={formData.contractLength}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="contractStartDate" className="block text-sm font-medium text-gray-700">
                                                        Contract Start Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="contractStartDate"
                                                        id="contractStartDate"
                                                        value={formData.contractStartDate}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* Services Section */}
                                    <div className="mt-6">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold">Services</h3>
                                            <button
                                                type="button"
                                                onClick={() => setShowAddServiceModal(true)}
                                                className="inline-flex items-center px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                <svg className="w-5 h-5 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                                </svg>
                                                Add Service
                                            </button>
                                        </div>
                                        <div className="mt-4 space-y-4">
                                            {isLoadingServices ? (
                                                <div className="flex justify-center items-center py-4">
                                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                                                </div>
                                            ) : services.length > 0 ? (
                                                <div className="space-y-4">
                                                    {services.map((service) => {
                                                        const status = getServiceStatus(service);
                                                        const getBackgroundColor = (type) => {
                                                            const lowerType = type?.toLowerCase() || '';
                                                            if (lowerType.includes('recycle')) {
                                                                return 'bg-green-50';
                                                            } else if (lowerType.includes('trash')) {
                                                                return 'bg-yellow-900 bg-opacity-10';
                                                            }
                                                            return 'bg-gray-50';
                                                        };
                                                        return (
                                                            <div key={service._id} className={`${getBackgroundColor(service.type)} p-4 rounded-lg`}>
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <h4 className="text-lg font-medium">
                                                                            {service.type} Service - {service.frequency}
                                                                        </h4>
                                                                        <p className="text-sm text-gray-600">
                                                                            {service.dumpster?.dumpsterSize} yard {service.dumpster?.type}
                                                                        </p>
                                                                        <p className="text-sm text-gray-600">
                                                                            Quantity: {service.quantity}
                                                                        </p>
                                                                        {service.frequency === 'weekly' && service.weekdays?.length > 0 && (
                                                                            <p className="text-sm text-gray-600">
                                                                                Days: {service.weekdays.join(', ')}
                                                                            </p>
                                                                        )}
                                                                        {service.frequency === 'biweekly' && service.biweeklyDay && (
                                                                            <p className="text-sm text-gray-600">
                                                                                Day: {service.biweeklyDay}
                                                                            </p>
                                                                        )}
                                                                        {service.frequency === 'monthly' && (
                                                                            <p className="text-sm text-gray-600">
                                                                                {service.monthlyWeek} {service.monthlyDay} of each month
                                                                            </p>
                                                                        )}
                                                                        {service.rate && (
                                                                            <p className="text-sm text-gray-600">
                                                                                Rate: ${service.rate}
                                                                            </p>
                                                                        )}
                                                                        {status && (
                                                                            <p className={`text-sm font-medium mt-2 ${status.color}`}>
                                                                                {status.text}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex space-x-2">
                                                                        <button
                                                                            onClick={() => handleEditService(service)}
                                                                            className="text-blue-600 hover:text-blue-800"
                                                                        >
                                                                            <FiEdit2 className="w-5 h-5" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <p className="text-center text-gray-500">No services found</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Extras Section */}
                                    <CustomerExtras 
                                        customer={customer} 
                                        onUpdate={handleExtraUpdate}
                                        key={`extras-${customer?._id}-${customer?.extras?.length || 0}`}
                                    />

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            onClick={handleSave}
                                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>

            {/* Add Service Modal */}
            {showAddServiceModal && (
                <AddServiceModal
                    isOpen={showAddServiceModal}
                    onClose={() => setShowAddServiceModal(false)}
                    customer={customer}
                    customerId={customer?._id}
                    onSubmit={handleAddService}
                />
            )}

            {/* Edit Service Modal */}
            {showEditServiceModal && selectedService && (
                <EditServiceModal
                    isOpen={showEditServiceModal}
                    onClose={() => {
                        setShowEditServiceModal(false);
                        setSelectedService(null);
                    }}
                    service={selectedService}
                    onSave={handleServiceUpdate}
                />
            )}
        </Transition.Root>
    );
}

export default EditDumpsterCustomerModal;
