import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiDownload, FiPrinter } from 'react-icons/fi';

function PrintWorkOrder({ workOrders, onClose }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const generateDocument = async (type = 'preview') => {
    try {
      setIsGenerating(true);
      setError(null);

      const response = await axios.post('/api/workorders/generate-document', {
        workOrders,
        type // 'preview' or 'download'
      }, {
        responseType: type === 'download' ? 'blob' : 'json'
      });

      if (type === 'download') {
        // Create a download link for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'work-orders.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        // Set preview URL
        setPreviewUrl(response.data.previewUrl);
      }
    } catch (err) {
      setError('Failed to generate document. Please try again.');
      console.error('Error generating document:', err);
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    generateDocument('preview');
  }, [workOrders]); // Regenerate preview when workOrders change

  return (
    <div className="fixed inset-y-0 right-0 w-[600px] bg-white shadow-lg flex flex-col">
      {/* Header */}
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-xl font-semibold">Print Preview</h2>
        <div className="flex gap-2">
          <button
            onClick={() => generateDocument('download')}
            disabled={isGenerating}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
          >
            <FiDownload />
            Download
          </button>
          <button
            onClick={() => window.print()}
            disabled={isGenerating}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 flex items-center gap-2"
          >
            <FiPrinter />
            Print
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto p-4">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}

        {isGenerating ? (
          <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : previewUrl ? (
          <iframe
            src={previewUrl}
            className="w-full h-full border-0"
            title="Work Order Preview"
          />
        ) : null}
      </div>
    </div>
  );
}

export default PrintWorkOrder;
