import axiosInstance from './api';

// Get all extras
export const getExtras = async () => {
    try {
        const response = await axiosInstance.get('/dumpsterextras');
        return response.data;
    } catch (error) {
        console.error('Error fetching extras:', error);
        throw error;
    }
};

// Create a new extra
export const createExtra = async (extraData) => {
    try {
        const response = await axiosInstance.post('/dumpsterextras', extraData);
        return response.data;
    } catch (error) {
        console.error('Error creating extra:', error);
        throw error;
    }
};

// Update an extra
export const updateExtra = async (id, extraData) => {
    try {
        const response = await axiosInstance.put(`/dumpsterextras/${id}`, extraData);
        return response.data;
    } catch (error) {
        console.error('Error updating extra:', error);
        throw error;
    }
};

// Delete an extra
export const deleteExtra = async (id) => {
    try {
        const response = await axiosInstance.delete(`/dumpsterextras/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting extra:', error);
        throw error;
    }
};

// Add extra to customer
export const addExtraToCustomer = async (customerId, extraData) => {
    try {
        console.log('Adding extra to customer:', customerId, extraData);
        const response = await axiosInstance.post(`/dumpsterextras/customer/${customerId}`, extraData);
        return response.data;
    } catch (error) {
        console.error('Error adding extra to customer:', error);
        throw error;
    }
};

// Remove extra from customer
export const removeExtraFromCustomer = async (customerId, extraId) => {
    try {
        const response = await axiosInstance.delete(`/dumpsterextras/customer/${customerId}/${extraId}`);
        return response.data;
    } catch (error) {
        console.error('Error removing extra from customer:', error);
        throw error;
    }
};
