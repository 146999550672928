import React, { useState, useEffect } from 'react';
import { FiPlus, FiEdit2, FiTrash2, FiMove } from 'react-icons/fi';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  updateRolesOrder,
} from '../../services/adminService';
import EditRoleForm from './EditRoleForm';
import DeleteRoleConfirmation from './DeleteRoleConfirmation';

function RoleManagement() {
  const [adminRole, setAdminRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  // Initialize sensors for DndContext
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      console.log('Fetched roles:', data);
      if (Array.isArray(data)) {
        // Sort roles based on the 'order' field
        data.sort((a, b) => a.order - b.order);

        // Separate the "Admin" role
        const admin = data.find(role => role.name === 'Admin');
        const otherRoles = data.filter(role => role.name !== 'Admin');

        setAdminRole(admin);
        setRoles(otherRoles);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (err) {
      console.error('Error fetching roles:', err);
      setError('Failed to fetch roles');
    }
  };

  const handleAddRole = async () => {
    try {
      const newRole = { name: 'New Role' };
      const createdRole = await createRole(newRole);
      setRoles([...roles, createdRole]); // Add to the bottom of the list
      setSelectedRole(createdRole);
      setShowEditForm(true); // Open the edit form for the new role
    } catch (err) {
      console.error('Error creating role:', err);
      setError('Failed to add role');
    }
  };

  const handleEditRole = (roleId) => {
    const role = roles.find(r => r._id === roleId);
    setSelectedRole(role);
    setShowEditForm(true);
  };

  const closeEditForm = () => {
    setShowEditForm(false);
    setSelectedRole(null);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedRole(null);
  };

  const handleDeleteRole = (roleId) => {
    const role = roles.find(r => r._id === roleId);
    setSelectedRole(role);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteRole = async () => {
    try {
      await deleteRole(selectedRole._id);
      setRoles(roles.filter(role => role._id !== selectedRole._id));
      setShowDeleteConfirmation(false);
      setSelectedRole(null);
    } catch (err) {
      console.error('Error deleting role:', err);
      setError('Failed to delete role');
    }
  };

  const handleSaveEdit = async (updatedRole) => {
    try {
      await updateRole(updatedRole._id, updatedRole);
      setRoles(
        roles.map(role => (role._id === updatedRole._id ? updatedRole : role))
      );
      setShowEditForm(false);
      setSelectedRole(null);
    } catch (err) {
      console.error('Error updating role:', err);
      setError('Failed to update role');
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = roles.findIndex(role => role._id === active.id);
      const newIndex = roles.findIndex(role => role._id === over.id);
      const newRoles = arrayMove(roles, oldIndex, newIndex);
      setRoles(newRoles);

      // Extract the ordered list of role IDs without the Admin role
      const orderedRoleIds = newRoles.map(role => role._id);

      console.log('Ordered role IDs:', orderedRoleIds);

      try {
        await updateRolesOrder(orderedRoleIds);
        console.log('Roles order updated successfully');
      } catch (err) {
        console.error('Error updating roles order:', err);
        console.error('Error details:', err.response?.data);
        setError('Failed to update roles order');
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Role Management</h2>
        <button 
          onClick={handleAddRole}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150"
        >
          <FiPlus className="mr-2" /> Add Role
        </button>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <div className="mt-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={roles.map(role => role._id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="overflow-hidden rounded-lg border border-gray-200">
              {/* Table Header */}
              <div className="bg-gray-50 border-b border-gray-200">
                <div className="flex items-center">
                  <div className="w-10"></div>
                  <div className="w-[250px] px-6 py-3">
                    <span className="text-sm font-medium text-gray-900">Role Name</span>
                  </div>
                  <div className="flex-1 px-6 py-3">
                    <span className="text-sm font-medium text-gray-900">Abilities</span>
                  </div>
                  <div className="w-24 pr-6 py-3 text-right">
                    <span className="text-sm font-medium text-gray-900">Actions</span>
                  </div>
                </div>
              </div>

              {/* Table Body */}
              <div className="divide-y divide-gray-200 bg-white">
                {/* Admin Role - Fixed at the Top */}
                {adminRole && (
                  <div className="flex items-center bg-indigo-50">
                    <div className="w-10"></div>
                    <div className="w-[250px] px-6 py-4">
                      <div className="flex items-center text-sm font-medium text-gray-900">
                        <span className="truncate">{adminRole.name}</span>
                        {adminRole.isDefault && (
                          <span className="ml-2 shrink-0 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                            Default
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex-1 px-6 py-4">
                      <div className="text-sm text-gray-600 flex flex-wrap gap-1">
                        {(adminRole.abilities || []).map((ability) => (
                          <span key={ability} className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 whitespace-nowrap">
                            {ability}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="w-24 pr-6 py-4">
                      <div className="flex justify-end gap-2">
                        {/* Admin role cannot be edited or deleted */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Other Roles */}
                {roles.map((role) => (
                  <SortableItem key={role._id} id={role._id}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <div className="w-[250px] px-6 py-4">
                          <div className="flex items-center text-sm text-gray-900">
                            <span className="truncate">{role.name}</span>
                            {role.isDefault && (
                              <span className="ml-2 shrink-0 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                Default
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex-1 px-6 py-4">
                          <div className="text-sm text-gray-600 flex flex-wrap gap-1">
                            {(role.abilities || []).map((ability) => (
                              <span key={ability} className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 whitespace-nowrap">
                                {ability}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="w-24 pr-6 py-4">
                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => handleEditRole(role._id)}
                              className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              <FiEdit2 className="h-4 w-4" />
                            </button>
                            <button
                              onClick={() => handleDeleteRole(role._id)}
                              className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              <FiTrash2 className="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SortableItem>
                ))}
              </div>
            </div>
          </SortableContext>
        </DndContext>
      </div>

      {/* Edit Role Form Modal */}
      {showEditForm && selectedRole && (
        <EditRoleForm
          role={selectedRole}
          onClose={closeEditForm}
          onSave={handleSaveEdit}
        />
      )}

      {/* Delete Role Confirmation Modal */}
      {showDeleteConfirmation && selectedRole && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-[9999]">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Role</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete the role "{selectedRole.name}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={closeDeleteConfirmation}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteRole}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoleManagement;