import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { getDumpsters } from '../../services/dumpsterService';
import { toast } from 'react-toastify';

const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const WEEKS = ['First', 'Second', 'Third', 'Fourth', 'Last'];

function AddServiceModal({ isOpen, onClose, customer, onSubmit, customerId }) {
    const [dumpsters, setDumpsters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        customer: customerId,
        dumpster: '',
        quantity: 1,
        frequency: '',
        weekdays: [],
        biweeklyDay: '',
        monthlyWeek: '',
        monthlyDay: '',
        nextServiceDate: '',
        type: 'Trash',
        locationNotes: '',
        serviceNotes: '',
        rate: '',
        useBillingAddress: true,
        serviceAddress: {
            street: customer?.billingAddress?.street || '',
            city: customer?.billingAddress?.city || '',
            state: customer?.billingAddress?.state || '',
            zip: customer?.billingAddress?.zip || ''
        }
    });

    const cleanedFormData = {
        ...formData,
        weekdays: formData.frequency === 'weekly' ? formData.weekdays : [],
        biweeklyDay: formData.frequency === 'biweekly' ? formData.biweeklyDay : null,
        monthlyWeek: formData.frequency === 'monthly' ? formData.monthlyWeek : null,
        monthlyDay: formData.frequency === 'monthly' ? formData.monthlyDay : null,
    };

    useEffect(() => {
        fetchDumpsters();
    }, []);

    useEffect(() => {
        if (formData.useBillingAddress && customer?.billingAddress) {
            setFormData(prev => ({
                ...prev,
                serviceAddress: {
                    street: customer.billingAddress.street,
                    city: customer.billingAddress.city,
                    state: customer.billingAddress.state,
                    zip: customer.billingAddress.zip
                }
            }));
        }
    }, [formData.useBillingAddress, customer]);

    const fetchDumpsters = async () => {
        try {
            const data = await getDumpsters();
            setDumpsters(data);
        } catch (error) {
            console.error('Error fetching dumpsters:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('serviceAddress.')) {
            const addressField = name.split('.')[1];
            setFormData(prev => ({
                ...prev,
                serviceAddress: {
                    ...prev.serviceAddress,
                    [addressField]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleWeekdayToggle = (day) => {
        setFormData(prev => ({
            ...prev,
            weekdays: prev.weekdays.includes(day)
                ? prev.weekdays.filter(d => d !== day)
                : [...prev.weekdays, day]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate required fields based on frequency
        if (formData.frequency === 'weekly' && formData.weekdays.length === 0) {
            toast.error('Please select at least one weekday for weekly service');
            return;
        }
        if (formData.frequency === 'biweekly' && !formData.biweeklyDay) {
            toast.error('Please select a day for biweekly service');
            return;
        }
        if (formData.frequency === 'monthly' && (!formData.monthlyWeek || !formData.monthlyDay)) {
            toast.error('Please select both week and day for monthly service');
            return;
        }

        try {
            const serviceData = {
                ...cleanedFormData,
                rate: parseFloat(formData.rate)
            };

            // If using billing address, remove service address
            if (serviceData.useBillingAddress) {
                delete serviceData.serviceAddress;
            }

            await onSubmit(serviceData);
            onClose();
            toast.success('Service added successfully');
        } catch (error) {
            toast.error('Failed to add service: ' + error.message);
        }
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[10000]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[10000]" />
                </Transition.Child>

                <div className="fixed inset-0 z-[10000] overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <form onSubmit={handleSubmit}>
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="space-y-4">
                                            <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                                                Add New Service
                                            </Dialog.Title>

                                            {/* Dumpster Selection */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Dumpster</label>
                                                <select
                                                    name="dumpster"
                                                    value={formData.dumpster}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="">Select a dumpster</option>
                                                    {dumpsters.map(dumpster => (
                                                        <option key={dumpster._id} value={dumpster._id}>
                                                            {dumpster.name} - {dumpster.dumpsterSize} yards ({dumpster.type})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Quantity */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Quantity</label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    value={formData.quantity}
                                                    onChange={handleChange}
                                                    min="1"
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>

                                            {/* Use Billing Address Toggle */}
                                            <div className="flex items-center space-x-2">
                                                <Switch
                                                    checked={formData.useBillingAddress}
                                                    onChange={(checked) => setFormData(prev => ({ ...prev, useBillingAddress: checked }))}
                                                    className={`${
                                                        formData.useBillingAddress ? 'bg-blue-600' : 'bg-gray-200'
                                                    } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                                >
                                                    <span className="sr-only">Use billing address</span>
                                                    <span
                                                        className={`${
                                                            formData.useBillingAddress ? 'translate-x-1.5' : '-translate-x-1.5'
                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                                    />
                                                </Switch>
                                                <label className="text-sm font-medium text-gray-700">Use billing address</label>
                                            </div>

                                            {/* Service Address */}
                                            <div className="space-y-3">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Street</label>
                                                    <input
                                                        type="text"
                                                        name="serviceAddress.street"
                                                        value={formData.serviceAddress.street}
                                                        onChange={handleChange}
                                                        disabled={formData.useBillingAddress}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100"
                                                    />
                                                </div>
                                                <div className="grid grid-cols-3 gap-3">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">City</label>
                                                        <input
                                                            type="text"
                                                            name="serviceAddress.city"
                                                            value={formData.serviceAddress.city}
                                                            onChange={handleChange}
                                                            disabled={formData.useBillingAddress}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">State</label>
                                                        <input
                                                            type="text"
                                                            name="serviceAddress.state"
                                                            value={formData.serviceAddress.state}
                                                            onChange={handleChange}
                                                            disabled={formData.useBillingAddress}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">ZIP Code</label>
                                                        <input
                                                            type="text"
                                                            name="serviceAddress.zip"
                                                            value={formData.serviceAddress.zip}
                                                            onChange={handleChange}
                                                            disabled={formData.useBillingAddress}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Service Frequency */}
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700">Service Frequency</label>
                                                <select
                                                    name="frequency"
                                                    value={formData.frequency}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="weekly">Weekly</option>
                                                    <option value="biweekly">Biweekly</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="oncall">On Call</option>
                                                </select>
                                            </div>

                                            {/* Weekly Service Days */}
                                            {formData.frequency === 'weekly' && (
                                                <div className="mb-4">
                                                    <label className="block text-sm font-medium text-gray-700">Service Days</label>
                                                    <div className="mt-2 space-y-2">
                                                        {WEEKDAYS.map((day) => (
                                                            <label key={day} className="inline-flex items-center mr-4">
                                                                <input
                                                                    type="checkbox"
                                                                    name="weekdays"
                                                                    value={day}
                                                                    checked={formData.weekdays.includes(day)}
                                                                    onChange={handleWeekdayToggle}
                                                                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                                />
                                                                <span className="ml-2">{day}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Biweekly Service Day */}
                                            {formData.frequency === 'biweekly' && (
                                                <div className="mb-4">
                                                    <label className="block text-sm font-medium text-gray-700">Service Day</label>
                                                    <select
                                                        name="biweeklyDay"
                                                        value={formData.biweeklyDay}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="">Select Day</option>
                                                        {WEEKDAYS.map((day) => (
                                                            <option key={day} value={day}>{day}</option>
                                                        ))}
                                                    </select>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        This date determines which weeks the service will occur. Service will repeat every two weeks from this date.
                                                    </p>
                                                </div>
                                            )}

                                            {/* Monthly Service Settings */}
                                            {formData.frequency === 'monthly' && (
                                                <>
                                                    <div className="mb-4">
                                                        <label className="block text-sm font-medium text-gray-700">Week of Month</label>
                                                        <select
                                                            name="monthlyWeek"
                                                            value={formData.monthlyWeek}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        >
                                                            <option value="">Select Week</option>
                                                            {WEEKS.map((week) => (
                                                                <option key={week} value={week}>{week}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="block text-sm font-medium text-gray-700">Day of Week</label>
                                                        <select
                                                            name="monthlyDay"
                                                            value={formData.monthlyDay}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        >
                                                            <option value="">Select Day</option>
                                                            {WEEKDAYS.map((day) => (
                                                                <option key={day} value={day}>{day}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </>
                                            )}

                                            {/* Next Service Date - Show for all frequencies except oncall */}
                                            {formData.frequency && formData.frequency !== 'oncall' && (
                                                <div className="mb-4">
                                                    <label className="block text-sm font-medium text-gray-700">Next Service Date</label>
                                                    <input
                                                        type="date"
                                                        name="nextServiceDate"
                                                        value={formData.nextServiceDate}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        required
                                                    />
                                                </div>
                                            )}

                                            {/* Service Type */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Service Type</label>
                                                <select
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="Trash">Trash</option>
                                                    <option value="Recycle">Recycle</option>
                                                </select>
                                            </div>

                                            {/* Notes */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Location Notes</label>
                                                <textarea
                                                    value={formData.locationNotes || ''}
                                                    onChange={(e) => setFormData({ ...formData, locationNotes: e.target.value })}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    rows="2"
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Rate ($)</label>
                                                <input
                                                    type="number"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleChange}
                                                    onWheel={(e) => e.target.blur()}
                                                    step="0.01"
                                                    min="0"
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Service Notes</label>
                                                <textarea
                                                    name="serviceNotes"
                                                    value={formData.serviceNotes}
                                                    onChange={handleChange}
                                                    rows="2"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Enter any service-specific notes..."
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                        >
                                            Add Service
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AddServiceModal;
