import React, { useState, useEffect } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { useAuth } from '../../hooks/useAuth';
import { checkAbilityPermissions } from '../../services/authService';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { getDumpsters, createDumpster, updateDumpster, deleteDumpster } from '../../services/dumpsterService';

function DumpsterSizeManagement() {
    const { user } = useAuth();
    const [dumpsters, setDumpsters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedDumpster, setSelectedDumpster] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        dumpsterSize: '',
        type: '',
        description: ''
    });

    const canManageDumpsters = checkAbilityPermissions(user, 'CanManageDumpsters');

    useEffect(() => {
        fetchDumpsters();
    }, []);

    const fetchDumpsters = async () => {
        try {
            setIsLoading(true);
            const data = await getDumpsters();
            setDumpsters(data);
            setError(null);
        } catch (err) {
            setError('Failed to load dumpsters');
            console.error('Error fetching dumpsters:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selectedDumpster) {
                await updateDumpster(selectedDumpster._id, formData);
                toast.success('Dumpster updated successfully');
            } else {
                await createDumpster(formData);
                toast.success('Dumpster created successfully');
            }
            await fetchDumpsters();
            setShowAddModal(false);
            setShowEditModal(false);
            setFormData({ name: '', dumpsterSize: '', type: '', description: '' });
        } catch (error) {
            toast.error(error.message || 'Error saving dumpster');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this dumpster?')) {
            try {
                await deleteDumpster(id);
                toast.success('Dumpster deleted successfully');
                await fetchDumpsters();
            } catch (error) {
                toast.error(error.message || 'Error deleting dumpster');
            }
        }
    };

    const handleEdit = (dumpster) => {
        setSelectedDumpster(dumpster);
        setFormData({
            name: dumpster.name,
            dumpsterSize: dumpster.dumpsterSize,
            type: dumpster.type,
            description: dumpster.description || ''
        });
        setShowEditModal(true);
    };

    const DumpsterModal = ({ isOpen, onClose, isEdit }) => (
        <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="relative bg-white rounded-lg p-8 max-w-md w-full mx-4">
                    <Dialog.Title className="text-lg font-medium mb-4">
                        {isEdit ? 'Edit Dumpster' : 'Add New Dumpster'}
                    </Dialog.Title>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Size (yards)</label>
                            <input
                                type="number"
                                value={formData.dumpsterSize}
                                onChange={(e) => setFormData({ ...formData, dumpsterSize: e.target.value })}
                                required
                                step="0.01"
                                min="0.01"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Type</label>
                            <select
                                value={formData.type}
                                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value="">Select Type</option>
                                <option value="Front Load">Front Load</option>
                                <option value="Roll Off">Roll Off</option>
                                <option value="Rear Load">Rear Load</option>
                                <option value="Zarn Cart">Zarn Cart</option>
                                <option value="Bin">Bin</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                rows="3"
                            />
                        </div>
                        <div className="flex justify-end space-x-3 mt-6">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                            >
                                {isEdit ? 'Update' : 'Create'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );

    if (isLoading) return <div className="p-4">Loading...</div>;
    if (error) return <div className="p-4 text-red-600">{error}</div>;

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold">Manage Dumpster Sizes</h2>
                {canManageDumpsters && (
                    <button
                        onClick={() => {
                            setSelectedDumpster(null);
                            setFormData({ name: '', dumpsterSize: '', type: '', description: '' });
                            setShowAddModal(true);
                        }}
                        className="flex items-center space-x-1 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                        <MdAdd className="w-5 h-5" />
                        <span>Add Dumpster</span>
                    </button>
                )}
            </div>

            <div className="bg-white shadow overflow-hidden rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size (yards)</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                            {canManageDumpsters && (
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {dumpsters.map((dumpster) => (
                            <tr key={dumpster._id}>
                                <td className="px-6 py-4 whitespace-nowrap">{dumpster.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{dumpster.dumpsterSize}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{dumpster.type}</td>
                                <td className="px-6 py-4">{dumpster.description}</td>
                                {canManageDumpsters && (
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() => handleEdit(dumpster)}
                                            className="text-blue-600 hover:text-blue-900 mr-4"
                                        >
                                            <FiEdit2 className="w-5 h-5" />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(dumpster._id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            <FiTrash2 className="w-5 h-5" />
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <DumpsterModal
                isOpen={showAddModal}
                onClose={() => setShowAddModal(false)}
                isEdit={false}
            />
            <DumpsterModal
                isOpen={showEditModal}
                onClose={() => setShowEditModal(false)}
                isEdit={true}
            />
        </div>
    );
}

export default DumpsterSizeManagement;
