import React from 'react';
import { format, startOfWeek, addDays, isSameDay, parseISO, addWeeks } from 'date-fns';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

function WeeklyCalendar({ services, currentDate, onPrevRange, onNextRange }) {
    // Calendar Configuration
    const startDate = startOfWeek(currentDate, { weekStartsOn: 0 }); // Start from Sunday
    const numberOfWeeks = 4;
    
    // Week Generation Logic
    const weeks = [...Array(numberOfWeeks)].map((_, weekIndex) => {
        const weekStart = addWeeks(startDate, weekIndex);
        return [...Array(7)].map((_, dayIndex) => {
            const date = addDays(weekStart, dayIndex);
            const dayServices = services.filter(service => {
                if (!service.nextServiceDate) return false;
                const serviceDate = parseISO(service.nextServiceDate);
                return isSameDay(serviceDate, date);
            });
            
            return {
                date,
                dayName: format(date, 'EEE'),
                dayNumber: format(date, 'd'),
                isToday: isSameDay(date, new Date()),
                services: dayServices
            };
        });
    });

    // Service Counting Helper
    const getServiceCounts = (services) => {
        return services.reduce((acc, service) => {
            const type = service.type || 'Trash';
            acc[type] = (acc[type] || 0) + 1;
            return acc;
        }, {});
    };

    return (
        <div className="bg-white shadow h-full flex flex-col">
            {/* Fixed Header */}
            <div className="flex justify-between items-center border-b bg-white">
                <button
                    onClick={onPrevRange}
                    className="p-2 hover:bg-gray-100 rounded-full"
                >
                    <FiChevronLeft className="w-5 h-5" />
                </button>
                <div className="flex flex-col items-center">
                    <h2 className="text-lg font-semibold">
                        {format(startDate, 'MMMM d')} - {format(addDays(addWeeks(startDate, numberOfWeeks - 1), 6), 'MMMM d, yyyy')}
                    </h2>
                </div>
                <button
                    onClick={onNextRange}
                    className="p-2 hover:bg-gray-100 rounded-full"
                >
                    <FiChevronRight className="w-5 h-5" />
                </button>
            </div>

            {/* Scrollable Calendar Content */}
            <div className="flex-1 overflow-auto min-h-0">
                {weeks.map((week, weekIndex) => (
                    <div 
                        key={weekIndex}
                        className="grid grid-cols-7 border-b min-w-[800px]"
                    >
                        {week.map(({ dayName, dayNumber, isToday, date, services: dayServices }) => (
                            <div 
                                key={date.toISOString()}
                                className={`
                                    bg-white flex flex-col min-h-[120px]
                                    ${isToday ? 'bg-blue-50 border-[5px] border-red-500' : 'border-r last:border-r-0'}
                                `}
                            >
                                <div className={`
                                    text-sm font-semibold px-1
                                    ${isToday ? 'text-blue-600' : 'text-gray-900'}
                                `}>
                                    {dayName} {dayNumber}
                                </div>
                                
                                {/* Service Counts Display */}
                                {dayServices.length > 0 && (
                                    <div className="mt-1 px-1">
                                        {Object.entries(getServiceCounts(dayServices)).map(([type, count]) => (
                                            <div 
                                                key={type}
                                                className="text-xs px-2 py-1 rounded bg-blue-100 text-blue-800 mb-1"
                                            >
                                                {type}: {count}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WeeklyCalendar;
