import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { getDumpsters, deleteService } from '../../services/dumpsterService';
import { toast } from 'react-toastify';

const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const WEEKS = ['First', 'Second', 'Third', 'Fourth', 'Last'];

const calculateNextServiceDate = (frequency, weekdays, biweeklyDay, monthlyWeek, monthlyDay, isPostponed, resumeDate, currentDate = null) => {
    const today = currentDate || new Date();
    let nextDate = new Date(today);

    // If service is postponed and we have a resume date, use that as our starting point
    if (isPostponed && resumeDate) {
        const resumeDateTime = new Date(resumeDate);
        if (resumeDateTime > today) {
            nextDate = resumeDateTime;
        }
    }

    switch (frequency) {
        case 'weekly':
            if (weekdays && weekdays.length > 0) {
                let daysToAdd = 7;
                const todayDay = nextDate.getDay();
                const weekdayIndices = weekdays.map(day => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day));
                weekdayIndices.forEach(weekday => {
                    const diff = weekday - todayDay;
                    if (diff > 0 && diff < daysToAdd) daysToAdd = diff;
                });
                nextDate.setDate(nextDate.getDate() + daysToAdd);
            }
            break;

        case 'biweekly':
            if (biweeklyDay) {
                const targetDayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(biweeklyDay);
                const todayDayIndex = nextDate.getDay();
                let daysToAdd = (targetDayIndex + 7 - todayDayIndex) % 7;
                if (daysToAdd === 0) daysToAdd = 7; // If it's the same day, move to next week
                nextDate.setDate(nextDate.getDate() + daysToAdd);
            }
            break;

        case 'monthly':
            if (monthlyWeek && monthlyDay) {
                const monthlyDayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(monthlyDay);
                const weekIndex = ['First', 'Second', 'Third', 'Fourth', 'Last'].indexOf(monthlyWeek);
                
                // Start from first day of current month
                let currentMonth = nextDate.getMonth();
                let currentYear = nextDate.getFullYear();
                
                // Function to find the specified weekday occurrence in a month
                const findWeekdayOccurrence = (year, month, dayIndex, weekNum) => {
                    let date = new Date(year, month, 1);
                    let count = 0;
                    
                    if (weekNum === 4) { // "Last"
                        // Start from the last day of the month
                        date = new Date(year, month + 1, 0);
                        while (date.getDay() !== dayIndex) {
                            date.setDate(date.getDate() - 1);
                        }
                        return date;
                    }
                    
                    // Find the nth occurrence
                    while (count <= weekNum) {
                        if (date.getDay() === dayIndex) {
                            count++;
                            if (count > weekNum) break;
                        }
                        date.setDate(date.getDate() + 1);
                    }
                    return date;
                };
                
                // Find the occurrence in current month
                let targetDate = findWeekdayOccurrence(currentYear, currentMonth, monthlyDayIndex, weekIndex);
                
                // If the target date has passed or is during postponement, move to next month
                while (targetDate < nextDate) {
                    currentMonth++;
                    if (currentMonth > 11) {
                        currentMonth = 0;
                        currentYear++;
                    }
                    targetDate = findWeekdayOccurrence(currentYear, currentMonth, monthlyDayIndex, weekIndex);
                }
                
                nextDate = targetDate;
            }
            break;
    }

    return nextDate;
};

// Calculate services per billing cycle based on frequency and billing cycle
const calculateServicesPerBillingCycle = (frequency, billingCycle) => {
    console.log('Calculating services for:', { frequency, billingCycle });
    
    if (!frequency) return 1;  // Default to 1 if no frequency

    // How many services per month for each frequency
    const servicesPerMonth = {
        'weekly': 4,
        'biweekly': 2,
        'monthly': 1,
        'oncall': 1
    };

    // How many months in each billing cycle
    const monthsPerBillingCycle = {
        'monthly': 1,
        'quarterly': 3,
        'semi-annual': 6,
        'annual': 12
    };

    const cycleMonths = monthsPerBillingCycle[(billingCycle || 'monthly').toLowerCase()] || 1;
    const servicesPerMonthCount = servicesPerMonth[frequency.toLowerCase()] || 1;
    const totalServices = servicesPerMonthCount * cycleMonths;
    
    console.log('Calculation details:', {
        cycleMonths,
        servicesPerMonthCount,
        totalServices
    });
    
    return totalServices;
};

// Calculate cost per service
const calculateCostPerService = (formData, service) => {
    console.log('Calculating cost with:', { formData, service });
    
    if (!formData?.rate || !formData?.frequency) {
        console.log('Missing rate or frequency');
        return '';
    }
    
    const rate = parseFloat(formData.rate);
    if (isNaN(rate) || rate <= 0) {
        console.log('Invalid rate:', rate);
        return '';
    }
    
    const servicesPerCycle = calculateServicesPerBillingCycle(formData.frequency, service?.customer?.billingCycle);
    console.log('Services per cycle:', servicesPerCycle);
    
    if (servicesPerCycle <= 0) {
        console.log('Invalid services per cycle');
        return '';
    }
    
    const costPerService = rate / servicesPerCycle;
    console.log('Final cost per service:', costPerService);
    
    return isNaN(costPerService) ? '' : costPerService.toFixed(2);
};

function EditServiceModal({ isOpen, onClose, service, onSave, onDelete }) {
    const [dumpsters, setDumpsters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    // Initialize form data
    const [formData, setFormData] = useState({
        dumpster: '',
        quantity: 1,
        frequency: '',
        weekdays: [],
        biweeklyDay: '',
        monthlyWeek: '',
        monthlyDay: '',
        nextServiceDate: '',
        rate: '',
        serviceType: 'Trash',
        isPostponed: false,
        resumeDate: '',
        onHold: false,
        useBillingAddress: false,
        serviceAddress: {
            street: '',
            city: '',
            state: '',
            zip: ''
        }
    });

    // Effect to initialize form data when service prop changes
    useEffect(() => {
        if (service) {
            console.log('Loading service data:', service);
            // First set the basic form data
            const initialFormData = {
                dumpster: service.dumpster?._id || '',
                quantity: service.quantity || 1,
                frequency: service.frequency || '',
                weekdays: service.weekdays || [],
                biweeklyDay: service.biweeklyDay || '',
                monthlyWeek: service.monthlyWeek || '',
                monthlyDay: service.monthlyDay || '',
                nextServiceDate: service.nextServiceDate ? new Date(service.nextServiceDate).toISOString().split('T')[0] : '',
                rate: service.rate || '',
                serviceType: service.serviceType || 'Trash',
                isPostponed: service.isPostponed || false,
                resumeDate: service.resumeDate ? new Date(service.resumeDate).toISOString().split('T')[0] : '',
                onHold: service.onHold || false,
                useBillingAddress: service.useBillingAddress || false,
                serviceAddress: service.serviceAddress || {
                    street: '',
                    city: '',
                    state: '',
                    zip: ''
                }
            };

            // Calculate the next service date based on current settings
            const nextDate = calculateNextServiceDate(
                initialFormData.frequency,
                initialFormData.weekdays,
                initialFormData.biweeklyDay,
                initialFormData.monthlyWeek,
                initialFormData.monthlyDay,
                initialFormData.isPostponed,
                initialFormData.resumeDate
            );

            if (nextDate) {
                initialFormData.nextServiceDate = nextDate.toISOString().split('T')[0];
            }

            console.log('Setting form data to:', initialFormData);
            setFormData(initialFormData);
        }
    }, [service]);

    useEffect(() => {
        fetchDumpsters();
    }, []);

    const fetchDumpsters = async () => {
        try {
            const data = await getDumpsters();
            setDumpsters(data);
        } catch (error) {
            console.error('Error fetching dumpsters:', error);
            toast.error('Failed to load dumpsters');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prev => {
            const newData = {
                ...prev,
                [name]: value
            };

            // Calculate next service date when frequency-related fields change
            if (['frequency', 'weekdays', 'biweeklyDay', 'monthlyWeek', 'monthlyDay', 'isPostponed', 'resumeDate'].includes(name)) {
                const nextDate = calculateNextServiceDate(
                    newData.frequency,
                    newData.weekdays,
                    newData.biweeklyDay,
                    newData.monthlyWeek,
                    newData.monthlyDay,
                    newData.isPostponed,
                    newData.resumeDate
                );
                
                if (nextDate) {
                    newData.nextServiceDate = nextDate.toISOString().split('T')[0];
                }
            }

            return newData;
        });
    };

    const handleWeekdayToggle = (day) => {
        setFormData(prev => {
            const newWeekdays = prev.weekdays.includes(day)
                ? prev.weekdays.filter(d => d !== day)
                : [...prev.weekdays, day];

            const newData = {
                ...prev,
                weekdays: newWeekdays
            };

            // Calculate next service date when weekdays change
            if (prev.frequency === 'weekly') {
                const nextDate = calculateNextServiceDate(
                    prev.frequency,
                    newWeekdays,
                    prev.biweeklyDay,
                    prev.monthlyWeek,
                    prev.monthlyDay,
                    prev.isPostponed,
                    prev.resumeDate
                );
                
                if (nextDate) {
                    newData.nextServiceDate = nextDate.toISOString().split('T')[0];
                }
            }

            return newData;
        });
    };

    const handleDelete = async () => {
        try {
            await deleteService(service._id);
            toast.success('Service deleted successfully');
            onClose(); // Close the edit modal
            if (onDelete) {
                onDelete(); // Trigger parent refresh
            }
        } catch (error) {
            console.error('Error deleting service:', error);
            toast.error('Failed to delete service');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            // Validate required fields
            if (!formData.dumpster) {
                toast.error('Please select a dumpster');
                return;
            }

            if (!formData.rate || formData.rate <= 0) {
                toast.error('Please enter a valid rate');
                return;
            }

            // Validate required fields based on frequency
            if (formData.frequency === 'weekly' && formData.weekdays.length === 0) {
                toast.error('Please select at least one weekday for weekly service');
                return;
            }
            if (formData.frequency === 'biweekly' && !formData.biweeklyDay) {
                toast.error('Please select a day for biweekly service');
                return;
            }
            if (formData.frequency === 'monthly' && (!formData.monthlyWeek || !formData.monthlyDay)) {
                toast.error('Please select both week and day for monthly service');
                return;
            }

            // Validate resume date if service is postponed
            if (formData.isPostponed && !formData.resumeDate) {
                toast.error('Please select a resume date when postponing service');
                return;
            }

            // Clear fields that aren't relevant to the selected frequency
            const cleanedFormData = {
                ...formData,
                weekdays: formData.frequency === 'weekly' ? formData.weekdays : [],
                biweeklyDay: formData.frequency === 'biweekly' ? formData.biweeklyDay : null,
                monthlyWeek: formData.frequency === 'monthly' ? formData.monthlyWeek : null,
                monthlyDay: formData.frequency === 'monthly' ? formData.monthlyDay : null,
            };

            // Calculate next service date
            const nextDate = calculateNextServiceDate(
                cleanedFormData.frequency,
                cleanedFormData.weekdays,
                cleanedFormData.biweeklyDay,
                cleanedFormData.monthlyWeek,
                cleanedFormData.monthlyDay,
                cleanedFormData.isPostponed,
                cleanedFormData.resumeDate
            );

            // Create service data with calculated next service date
            const serviceData = {
                ...cleanedFormData,
                rate: parseFloat(formData.rate),
                nextServiceDate: nextDate ? nextDate.toISOString() : null
            };

            // Log the data being saved
            console.log('Saving service with data:', serviceData);

            await onSave(serviceData);
            onClose();
            toast.success('Service updated successfully');
        } catch (error) {
            console.error('Error updating service:', error);
            toast.error('Failed to update service');
        }
    };

    if (isLoading) {
        return null;
    }

    return (
        <>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[10000]" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[10000]" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-[10000] overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                    <form onSubmit={handleSubmit}>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="space-y-4">
                                                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                                                    Edit Service
                                                </Dialog.Title>

                                                {/* Dumpster and Quantity */}
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="col-span-2">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Dumpster
                                                        </label>
                                                        <select
                                                            name="dumpster"
                                                            value={formData.dumpster}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        >
                                                            <option value="">Select Dumpster</option>
                                                            {dumpsters.map(dumpster => (
                                                                <option key={dumpster._id} value={dumpster._id}>
                                                                    {dumpster.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Quantity
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="quantity"
                                                            value={formData.quantity}
                                                            onChange={handleChange}
                                                            min="1"
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Use Billing Address Toggle */}
                                                <div className="flex items-center space-x-2">
                                                    <Switch
                                                        checked={formData.useBillingAddress}
                                                        onChange={(checked) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                useBillingAddress: checked
                                                            }));
                                                        }}
                                                        className={`${
                                                            formData.useBillingAddress ? 'bg-blue-600' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                                    >
                                                        <span
                                                            className={`${
                                                                formData.useBillingAddress ? 'translate-x-1.5' : '-translate-x-1.5'
                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                                        />
                                                    </Switch>
                                                    <span className="text-sm font-medium text-gray-700">Use Billing Address</span>
                                                </div>

                                                {/* Service Address - Only show if not using billing address */}
                                                {!formData.useBillingAddress && (
                                                    <div className="space-y-4">
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700">Street</label>
                                                            <input
                                                                type="text"
                                                                name="serviceAddress.street"
                                                                value={formData.serviceAddress.street}
                                                                onChange={handleChange}
                                                                required={!formData.useBillingAddress}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            />
                                                        </div>
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-2">
                                                                <label className="block text-sm font-medium text-gray-700">City</label>
                                                                <input
                                                                    type="text"
                                                                    name="serviceAddress.city"
                                                                    value={formData.serviceAddress.city}
                                                                    onChange={handleChange}
                                                                    required={!formData.useBillingAddress}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                                />
                                                            </div>
                                                            <div className="col-span-2">
                                                                <label className="block text-sm font-medium text-gray-700">State</label>
                                                                <input
                                                                    type="text"
                                                                    name="serviceAddress.state"
                                                                    value={formData.serviceAddress.state}
                                                                    onChange={handleChange}
                                                                    required={!formData.useBillingAddress}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                                />
                                                            </div>
                                                            <div className="col-span-2">
                                                                <label className="block text-sm font-medium text-gray-700">ZIP</label>
                                                                <input
                                                                    type="text"
                                                                    name="serviceAddress.zip"
                                                                    value={formData.serviceAddress.zip}
                                                                    onChange={handleChange}
                                                                    required={!formData.useBillingAddress}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Rate and Cost Per Service */}
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Rate ($) per billing cycle, {service?.customer?.billingCycle || 'Monthly'}
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="rate"
                                                            value={formData.rate}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Cost Per Service
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={calculateCostPerService(formData, service) || ''}
                                                            disabled
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-50 cursor-not-allowed"
                                                        />
                                                        <div className="text-xs text-gray-500 mt-1">
                                                            Calculated based on frequency and billing cycle
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Service Frequency */}
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Service Frequency</label>
                                                    <select
                                                        name="frequency"
                                                        value={formData.frequency}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="">Select Frequency</option>
                                                        <option value="weekly">Weekly</option>
                                                        <option value="biweekly">Biweekly</option>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="oncall">On Call</option>
                                                    </select>
                                                </div>

                                                {/* Weekly Service Days */}
                                                {formData.frequency === 'weekly' && (
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                                            Service Days
                                                        </label>
                                                        <div className="flex flex-wrap gap-2">
                                                            {WEEKDAYS.map(day => (
                                                                <button
                                                                    key={day}
                                                                    type="button"
                                                                    onClick={() => handleWeekdayToggle(day)}
                                                                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                                                                        formData.weekdays.includes(day)
                                                                            ? 'bg-blue-600 text-white'
                                                                            : 'bg-gray-200 text-gray-700'
                                                                    }`}
                                                                >
                                                                    {day}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Biweekly Service Day */}
                                                {formData.frequency === 'biweekly' && (
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">Service Day</label>
                                                        <select
                                                            name="biweeklyDay"
                                                            value={formData.biweeklyDay}
                                                            onChange={handleChange}
                                                            required
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        >
                                                            <option value="">Select Day</option>
                                                            {WEEKDAYS.map(day => (
                                                                <option key={day} value={day}>{day}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}

                                                {/* Monthly options */}
                                                {formData.frequency === 'monthly' && (
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700">
                                                                    Week of Month
                                                                </label>
                                                                <select
                                                                    name="monthlyWeek"
                                                                    value={formData.monthlyWeek}
                                                                    onChange={handleChange}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                                >
                                                                    <option value="">Select Week</option>
                                                                    {['First', 'Second', 'Third', 'Fourth', 'Last'].map(week => (
                                                                        <option key={week} value={week}>{week}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700">
                                                                    Day of Week
                                                                </label>
                                                                <select
                                                                    name="monthlyDay"
                                                                    value={formData.monthlyDay}
                                                                    onChange={handleChange}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                                >
                                                                    <option value="">Select Day</option>
                                                                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                                                                        <option key={day} value={day}>{day}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Next Service Date - Calculated and read-only */}
                                                {formData.frequency !== 'oncall' && (
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Next Service Date
                                                        </label>
                                                        <div className="mt-1 relative">
                                                            <input
                                                                type="date"
                                                                value={formData.nextServiceDate}
                                                                disabled
                                                                className="bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm cursor-not-allowed"
                                                            />
                                                            <div className="text-xs text-gray-500 mt-1">
                                                                Next service date is automatically calculated based on frequency settings
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Postpone Service Section */}
                                                <div className="flex items-center space-x-2">
                                                    <Switch
                                                        checked={formData.isPostponed}
                                                        onChange={(checked) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                isPostponed: checked,
                                                                // Clear resume date if turning off postpone
                                                                resumeDate: checked ? prev.resumeDate : ''
                                                            }));
                                                        }}
                                                        className={`${
                                                            formData.isPostponed ? 'bg-blue-600' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                                    >
                                                        <span
                                                            className={`${
                                                                formData.isPostponed ? 'translate-x-1.5' : '-translate-x-1.5'
                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                                        />
                                                    </Switch>
                                                    <span className="text-sm font-medium text-gray-700">Postpone Service</span>
                                                </div>

                                                {formData.isPostponed && (
                                                    <div className="ml-11">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Resume Service Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="resumeDate"
                                                            value={formData.resumeDate}
                                                            onChange={handleChange}
                                                            required={formData.isPostponed}
                                                            min={new Date().toISOString().split('T')[0]}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        />
                                                        <div className="text-xs text-gray-500 mt-1">
                                                            Service will resume on this date
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Service Type */}
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Service Type</label>
                                                    <select
                                                        name="serviceType"
                                                        value={formData.serviceType}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="Trash">Trash</option>
                                                        <option value="Recycle">Recycle</option>
                                                    </select>
                                                </div>

                                                {/* Notes */}
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Location Notes</label>
                                                    <textarea
                                                        name="locationNotes"
                                                        value={formData.locationNotes}
                                                        onChange={handleChange}
                                                        rows="2"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Enter any location-specific notes..."
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Service Notes</label>
                                                    <textarea
                                                        name="serviceNotes"
                                                        value={formData.serviceNotes}
                                                        onChange={handleChange}
                                                        rows="2"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Enter any service-specific notes..."
                                                    />
                                                </div>

                                                {/* Service Status Controls */}
                                                <div className="space-y-4 mt-6 border-t pt-4">
                                                    <h4 className="text-lg font-medium text-gray-900">Service Status</h4>

                                                    {/* Place on Hold */}
                                                    <div className="flex items-center">
                                                        <Switch
                                                            checked={formData.onHold}
                                                            onChange={(checked) => {
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    onHold: checked,
                                                                    isPostponed: checked ? false : prev.isPostponed,
                                                                    resumeDate: checked ? '' : prev.resumeDate
                                                                }));
                                                            }}
                                                            className={`${
                                                                formData.onHold ? 'bg-blue-600' : 'bg-gray-200'
                                                            } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                                        >
                                                            <span
                                                                className={`${
                                                                    formData.onHold ? 'translate-x-1.5' : '-translate-x-1.5'
                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                                            />
                                                        </Switch>
                                                        <span className="text-sm font-medium text-gray-700">Place Service on Hold</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                            >
                                                Save Changes
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowDeleteConfirm(true)}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:mt-0 sm:w-auto sm:mr-3"
                                            >
                                                Delete Service
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Delete Confirmation Modal */}
            <Transition.Root show={showDeleteConfirm} as={Fragment}>
                <Dialog as="div" className="relative z-[10001]" onClose={setShowDeleteConfirm}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                </svg>
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    Delete Service
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Are you sure you want to delete this service? This action cannot be undone.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setShowDeleteConfirm(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default EditServiceModal;
