import React, { useState, useEffect } from 'react';
import { FiGrid, FiFileText, FiClipboard, FiTruck, FiPackage, FiSettings } from 'react-icons/fi';
import { PiGauge } from "react-icons/pi";
import { LiaDumpsterSolid } from "react-icons/lia";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { checkAbilityPermissions, checkPagePermissions } from '../services/authService';

function NavigationBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  
  // Permission states
  const [permissions, setPermissions] = useState({
    canViewAllWorkOrders: false,
    canUpdateMeters: false,
    canViewEquipment: false,
    canViewParts: false,
    canViewDumpsters: false,
    canScheduleDumpsters: false,
    isAdmin: false
  });

  // Get active tab from pathname
  const getActiveTab = (pathname) => {
    const path = pathname.split('/')[1];
    // If path is empty (root path '/') or 'dashboard', return 'welcome'
    return (!path || path === 'dashboard') ? 'welcome' : path;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab(location.pathname));

  // Update active tab when location changes
  useEffect(() => {
    setActiveTab(getActiveTab(location.pathname));
  }, [location]);

  // Check permissions when user is loaded
  useEffect(() => {
    const checkPermissions = async () => {
      if (!user) return;

      console.log('User object:', user);
      console.log('User abilities:', user.abilities);

      try {
        // Map the permissions to the actual ability names
        const viewAll = user.abilities.includes('CanViewAllWorkOrders');
        const meters = user.abilities.includes('CanUpdateEquipmentMeters');
        const equipment = user.abilities.includes('CanViewEquipment') || user.abilities.includes('CanEditEquipment');
        const parts = user.abilities.includes('CanAddParts') || user.abilities.includes('CanEditParts');
        const dumpsters = user.abilities.includes('CanViewDumpsterSchedules');
        const scheduleDumpsters = user.abilities.includes('CanScheduleDumpsters');
        const admin = user.abilities.includes('IsAdmin');

        console.log('Permission check results:', {
          viewAll,
          meters,
          equipment,
          parts,
          dumpsters,
          scheduleDumpsters,
          admin
        });

        setPermissions({
          canViewAllWorkOrders: viewAll,
          canUpdateMeters: meters,
          canViewEquipment: equipment,
          canViewParts: parts,
          canViewDumpsters: dumpsters,
          canScheduleDumpsters: scheduleDumpsters,
          isAdmin: admin
        });
      } catch (error) {
        console.error('Error checking permissions:', error);
      }
    };

    checkPermissions();
  }, [user]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/${tab === 'welcome' ? '' : tab}`);
  };

  if (!user) return null;

  const iconClass = "w-6 h-6 stroke-[2]";

  const showDumpsterLink = permissions.canViewDumpsters || permissions.canScheduleDumpsters;

  return (
    <div className="flex fixed top-10 left-0 right-0 h-[40px] bg-gray-100 z-[9998]">
      <button
        className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
          hover:bg-gray-200 ${activeTab === 'welcome' ? 'bg-gray-300' : 'bg-transparent'}`}
        onClick={() => handleTabChange('welcome')}
        title="Dashboard"
      >
        <FiGrid className={iconClass} />
      </button>
      
      <button
        className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
          hover:bg-gray-200 ${activeTab === 'WorkOrders' ? 'bg-gray-300' : 'bg-transparent'}`}
        onClick={() => handleTabChange('WorkOrders')}
        title={permissions.canViewAllWorkOrders ? 'All Work Orders' : 'Your Work Orders'}
      >
        <FiFileText className={iconClass} />
      </button>

      {permissions.canUpdateMeters && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'updateMeters' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('updateMeters')}
          title="Update Meters"
        >
          <PiGauge className={iconClass} />
        </button>
      )}

      {permissions.canViewEquipment && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'equipment' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('equipment')}
          title="Equipment"
        >
          <FiTruck className={iconClass} />
        </button>
      )}

      {permissions.canViewParts && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'parts' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('parts')}
          title="Parts"
        >
          <FiPackage className={iconClass} />
        </button>
      )}

      {showDumpsterLink && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'Dumpsters' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('Dumpsters')}
          title="Dumpsters"
        >
          <LiaDumpsterSolid className={iconClass} />
        </button>
      )}

      {permissions.isAdmin && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'admin' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('admin')}
          title="Admin"
        >
          <FiSettings className={iconClass} />
        </button>
      )}
    </div>
  );
}

export default NavigationBar;