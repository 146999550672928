// Function to determine if a date is in "this" week or "next" week
export const getWeekLabel = (startDate) => {
    if (!startDate) return '';
    
    const today = new Date();
    const start = new Date(startDate);
    
    // Set time to noon UTC to avoid timezone issues
    today.setUTCHours(12, 0, 0, 0);
    start.setUTCHours(12, 0, 0, 0);
    
    // Get the difference in days
    const diffTime = start.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // If the start date is within the next 7 days (including today)
    if (diffDays >= 0 && diffDays <= 6) {
        return 'this';
    }
    // If the start date is within 7-13 days from today
    else if (diffDays > 6 && diffDays <= 13) {
        return 'next';
    }
    // For dates further in the future, default to "next"
    else if (diffDays > 13) {
        return 'next';
    }
    // For dates in the past
    else {
        return 'this';
    }
};

// Get day of week from date
const getDayOfWeek = (date) => {
    if (!date) return '';
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const d = new Date(date);
    // Use UTC day to avoid timezone issues
    return days[d.getUTCDay()];
};

// Function to format the service frequency display
export const formatServiceFrequency = (service) => {
    if (!service) return '';
    
    const { frequency, weekdays, nextServiceDate, monthlyWeek, monthlyDay } = service;
    console.log('Formatting service:', { frequency, weekdays, nextServiceDate, monthlyWeek, monthlyDay });
    
    if (frequency === 'biweekly') {
        const weekLabel = getWeekLabel(nextServiceDate);
        // Get day from weekdays array if available, otherwise from nextServiceDate
        const dayOfWeek = (weekdays?.length > 0) ? weekdays[0].toUpperCase() : getDayOfWeek(nextServiceDate);
        if (dayOfWeek) {
            return `[${dayOfWeek}(${weekLabel})]`;
        }
    } else if (frequency === 'weekly' && weekdays?.length > 0) {
        const dayOfWeek = weekdays[0].toUpperCase();
        return `[${dayOfWeek}]`;
    } else if (frequency === 'monthly') {
        // For monthly services, use monthlyDay and monthlyWeek
        const dayOfWeek = monthlyDay?.toUpperCase() || '';
        const week = monthlyWeek?.toLowerCase() || '';
        if (dayOfWeek && week) {
            return `[${dayOfWeek}(${week})]`;
        }
    } else if (frequency === 'oncall') {
        return '[ONCALL]';
    }
    
    return frequency || '';
};
