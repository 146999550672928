import axiosInstance from './api';

// Create axios instance with default config
// const axiosInstance = axios.create({
//     withCredentials: true,
//     baseURL: API_URL
// });

// Add auth token to requests
// axiosInstance.interceptors.request.use(config => {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

// Customer endpoints
export const getCustomers = async () => {
    try {
        const response = await axiosInstance.get('/dumpsters/customers');
        return response.data;
    } catch (error) {
        console.error('Error getting customers:', error.response?.data || error);
        throw error;
    }
};

export const getCustomer = async (id) => {
    try {
        const response = await axiosInstance.get(`/dumpsters/customers/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error getting customer:', error.response?.data || error);
        throw error;
    }
};

export const createCustomer = async (customerData) => {
    try {
        console.log('Sending customer data:', customerData);
        const response = await axiosInstance.post('/dumpsters/customers', customerData);
        console.log('Create customer response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating customer:', error.response?.data || error);
        throw error;
    }
};

export const updateCustomer = async (customerId, updateData) => {
    try {
        if (!updateData || typeof updateData !== 'object') {
            throw new Error('Update data must be an object');
        }
        console.log('Update data:', updateData); // Debug log
        const response = await axiosInstance.put(`/dumpsters/customers/${customerId}`, updateData);
        return response.data;
    } catch (error) {
        console.error('Error updating customer:', error);
        throw error.response?.data?.error || error;
    }
};

export const deleteCustomer = async (id) => {
    try {
        console.log('Deleting customer with id:', id);
        const response = await axiosInstance.delete(`/dumpsters/customers/${id}`);
        console.log('Delete customer response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error deleting customer:', error.response?.data || error);
        throw error;
    }
};

// Service endpoints
export const getServices = async () => {
    try {
        const response = await axiosInstance.get('/dumpsters/services');
        return response.data;
    } catch (error) {
        console.error('Error getting services:', error.response?.data || error);
        throw error;
    }
};

export const createService = async (serviceData) => {
    try {
        console.log('Sending service data:', serviceData);
        const response = await axiosInstance.post('/dumpsters/services', serviceData);
        console.log('Create service response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating service:', error.response?.data || error);
        throw error;
    }
};

export const updateService = async (id, serviceData) => {
    try {
        console.log('Sending service data:', serviceData);
        const response = await axiosInstance.put(`/dumpsters/services/${id}`, serviceData);
        console.log('Update service response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating service:', error.response?.data || error);
        throw error;
    }
};

export const deleteService = async (id) => {
    try {
        console.log('Deleting service with id:', id);
        const response = await axiosInstance.delete(`/dumpsters/services/${id}`);
        console.log('Delete service response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error deleting service:', error.response?.data || error);
        throw error;
    }
};

export const getDumpsterServices = async (customerId) => {
    try {
        const response = await axiosInstance.get(`/dumpsters/services/customer/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

// Dumpster CRUD operations
export const getDumpsters = async () => {
    try {
        const response = await axiosInstance.get('/dumpsters/dumpsters');
        return response.data;
    } catch (error) {
        console.error('Error getting dumpsters:', error.response?.data || error);
        throw error;
    }
};

export const createDumpster = async (dumpsterData) => {
    try {
        const response = await axiosInstance.post('/dumpsters/dumpsters', dumpsterData);
        return response.data;
    } catch (error) {
        console.error('Error creating dumpster:', error.response?.data || error);
        throw error;
    }
};

export const updateDumpster = async (id, dumpsterData) => {
    try {
        const response = await axiosInstance.put(`/dumpsters/dumpsters/${id}`, dumpsterData);
        return response.data;
    } catch (error) {
        console.error('Error updating dumpster:', error.response?.data || error);
        throw error;
    }
};

export const deleteDumpster = async (id) => {
    try {
        const response = await axiosInstance.delete(`/dumpsters/dumpsters/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting dumpster:', error.response?.data || error);
        throw error;
    }
};
